import React from "react";
import Button from "../button/Button";
import Dialogue from "../dialogue/Dialogue";
import Sequencer from "../sequencer/Sequencer";
import { UserInfoAction, UserInfoActionType } from "./state/reducer";

import styles from "./styles.module.scss";
import useTranslatedString, { TranslationKey } from "./translationConfig";

type NewsletterProps = {
    newsletter: boolean | null | undefined;
    dispatch: React.Dispatch<UserInfoAction>;
} 

const NewsletterDialogue = ({newsletter, dispatch}: NewsletterProps) => {

    const yesLabel = useTranslatedString(TranslationKey.NEWSLETTER_YES);
    const noLabel = useTranslatedString(TranslationKey.NEWSLETTER_NO);

    const questions = [
        useTranslatedString(TranslationKey.NEWSLETTER_QUESTION)
    ];

    const setNewsletter = (accepts: boolean|null) => {
        dispatch({type:UserInfoActionType.SET_NEWSLETTER, payload: accepts});
    }

    const Options = () => {
        const newsLetterClicked = (event: React.FormEvent<HTMLButtonElement>) => {
            if(newsletter === undefined || newsletter === null){
                setNewsletter(event.currentTarget.value === "yes");
            } else {
                setNewsletter(null);
            }
        }

        return (
            <Sequencer elements={[
                <Button 
                    label={yesLabel}
                    onClick={newsLetterClicked}
                    value="yes"
                    className={newsletter ? styles.accepted : styles.submit }
                    hidden={newsletter === false}
                    key="yes"
                />,
                <Button 
                    label={noLabel}
                    onClick={newsLetterClicked}
                    value="no"
                    className={newsletter === false ? styles.accepted : styles.submit }
                    hidden={newsletter === true}
                    key="no"
                />
            ]} skipAnimation={newsletter !== undefined}/>
        )
    }

    return (
        <Dialogue questions={questions} answer={React.createElement(Options)} animate={newsletter === undefined}/>
    )
}

export default NewsletterDialogue;
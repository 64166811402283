import { MediaEntry } from "../../packages/memoryWizard/components/input/text/TextInputService";
import { ItemMedia } from "../../packages/memoryWizard/memoryService";
import AudioPlayer from "../audio/audioPlayer";
import DmsImage from "../image/DmsImage";
import StatusMessage from "../mediaStatus/mediaStatus";

import { useDmsMedia } from "./useDmsMedia";

const MediaDisplay = (props: {item: ItemMedia | MediaEntry, showControls?: boolean}) => {
    const {item, showControls} = props;
    const { src, mime_type, ...rest } = item;
    const dmsId = "dmsId" in rest ? rest.dmsId : undefined;

    const { mediaSrc, showErrorMessage, mediaStatus, onError, onLoad} = useDmsMedia(src, dmsId, mime_type);
   
    return (
        <>
            {
                mime_type.startsWith("image")
                    ? mediaSrc 
                        ? <img src={mediaSrc} onError={onError} />
                        : <DmsImage dmsId={dmsId} onLoad={onLoad} />
                    : mime_type.startsWith("video")
                        ? <video src={mediaSrc} controls={showControls} onError={onError} onLoadedData={onLoad} /> 
                        : <AudioPlayer src={mediaSrc} dmsId={dmsId} hideControls={!showControls} onError={onError} onLoad={onLoad}/>
            }
            {
                showErrorMessage && <StatusMessage status={mediaStatus} />
            }
        </>
    );
}

export default MediaDisplay;
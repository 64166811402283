import { useLocizeString } from "../../../../../../state/hooks/utils";
import { TranslationConfig } from "../../translationConfig";

export enum TranslationKey {
    COMPLETED_LINE_1,
    COMPLETED_LINE_2,
    SEND_LABEL,
    SUBMITTING
}

const translationConfigs: {[key in TranslationKey]: TranslationConfig} = {
    [TranslationKey.COMPLETED_LINE_1]: {
        key: "completedInfoLine1",
        fallback: "Takk for at du delte ditt minne"
    },
    [TranslationKey.COMPLETED_LINE_2]: {
        key: "completedInfoLine2",
        fallback: "Innsamlingen vil bli publisert på nettsidene, og vil kunne bli brukt til forskning"
    },
    [TranslationKey.SEND_LABEL]: {
        key: "sendButtonLabel",
        fallback: "Send"
    },
    [TranslationKey.SUBMITTING]: {
        key: "submittingMessage",
        fallback: "Laster opp svaret ditt, venneligst vent"
    }
}

const useTranslatedString = (key: TranslationKey) => {
    const config = translationConfigs[key];
    return useLocizeString("submitForm", config.key, config.fallback);
}

export default useTranslatedString;
import { ItemMedia, ItemMediaWithSrc } from "../packages/memoryWizard/memoryService";

interface DirectUploadInfo {
    url: string;
    action: string;
    multimediaId: string;
    "user.email": string;  
}

interface PreviewInfo {
    action: string, 
    multimediaId: string, 
    urls: {
        mp3: string, //used for audio
        webm: string, //used for video
    }
}

export const uploadMedia =  (media: ItemMediaWithSrc) => {
    if(media.mime_type.includes("image") || media.mime_type.includes("file")){
        return uploadFile(media);
    } else {
        return getUploadInfo().then(info => directUpload(info, media))
    }
}

export const getPreviewUrl = (dmsId: string) => {
    return uploadCompleted(dmsId).then((result) => {
        return fetch(`${window._env_.REACT_APP_MEMORY_API}/upload/get_preview_url/${dmsId}`)
            .then(response => response.json())
            .then((data: PreviewInfo) => {
                return data.urls.webm || data.urls.mp3;
            })
    }).catch((reason) => {
        return Promise.reject(reason);
    });
}

const uploadCompleted = (dmsId: string) => {
    return new Promise((resolve,reject) => {
        getUploadStatus(dmsId).then(status => {
            if(status === "done"){
                resolve("ok")
            } else if(status === "unknown"){
                reject("Upload failed")
            } else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        uploadCompleted(dmsId).then(() => {resolve("ok")});
                    }, 10000);
                })
                .then((message) => {
                        resolve(message);
                })
                .catch((reason) => {
                    reject(reason)
                })
            }
        });
    });
}

const getUploadStatus = (dmsId: string) => {
    return fetch(`${window._env_.REACT_APP_MEMORY_API}/upload/status/${dmsId}`)
        .then(response => response.json())
        .then(data => {
            return data.status;
        })
}

const getUploadInfo = () => {
    return fetch(`${window._env_.REACT_APP_MEMORY_API}/upload/get_upload_url`)
        .then(response => response.json())
        .then((info: DirectUploadInfo) => {
            return info;
        }).catch(error => {
            return Promise.reject(error);
        });
}

const uploadFile = (media: ItemMediaWithSrc) => {
    return fetch(media.src)
    .then(response => response.blob())
    .then(blob =>{
        const formData = new FormData();
        formData.append("filename", "user_uploaded");
        formData.append("mediaType", media.mime_type);
        formData.append("title", media.description || "no title");
        formData.append("file", blob)
        
        return fetch(`${window._env_.REACT_APP_MEMORY_API}/upload/`, {
            method: "POST",
            body: formData
        })
    }).then(response => {
        if(response.ok){
            return response.json().then((data: {multimediaId: string}) => {
                return Promise.resolve(data.multimediaId);
            });
        } else {
            return Promise.reject("Upload failed");
        }
    })
}

const directUpload = (info: DirectUploadInfo, media: ItemMediaWithSrc) => {
    return fetch(media.src).then(response => response.blob())
        .then(blob =>{
            const formData = new FormData();
            formData.append("filename", "user_uploaded");
            formData.append("mediaType", media.mime_type);
            formData.append("title", media.description || "no title");
            formData.append("identifier", info.multimediaId);
            formData.append("file", blob)
            
            return fetch(info.url, {
                method: "POST",
                body: formData
            })
        }).then(response => {
            if(response.ok){
                return Promise.resolve(info.multimediaId);
            } else {
                return Promise.reject("Upload failed");
            }
        })
}



export const status = (dms_id: string) => {
    return fetch(`${window._env_.REACT_APP_MEMORY_API}/upload/status/${dms_id}`)
        .then(response => response.json())
        .then((url: string) => {
            return url;
        });
}
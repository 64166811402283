import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useRef } from "react";
import Button from "../../../../../components/button/Button";
import SubmitButton from "../../../../../components/button/SubmitButton";
import Sequencer from "../../../../../components/sequencer/Sequencer";
import { MemoryContext } from "../../../memoryWizard";
import { textValuesFromCurrentAnswer } from "../utils";
import styles from "./styles.module.scss";

const DateInput = () => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const context = useContext(MemoryContext);
    const initalValue = textValuesFromCurrentAnswer(context.service);

    const [focus, setFocus] = useState(false);
    
    useEffect(()=> {
        if(focus){
            inputRef.current?.focus();
        }
    }, [focus])
    
    const dateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsValid(event.target.value !== "" && event.target.checkValidity());
    }

    const submitHandler = (event: React.FormEvent) => {
        event.preventDefault();
        send();
    }

    const send = () => {
        if(isValid){
            context.service?.addAnswerWithText(inputRef.current!.value);
        }
        else {
            context.service?.addEmptyAnswer();
        }
    }

    const elements = [
        <div 
            className={styles.date} 
            key="input"
            onAnimationEnd={()=>{setFocus(true)}} 
        >
            <input 
                type="date" 
                ref={inputRef} 
                onInput={dateChanged}
                defaultValue={initalValue}
            />
            <Button 
                materialIcon="keyboard_return"
                type="submit"
            />
        </div>,
        <SubmitButton isValid={isValid} key="submit"/>
    ];

    return (
        <form className={styles.form} onSubmit={submitHandler}>
            <Sequencer elements={elements} />
        </form>
    );
}

export default DateInput;
import { BehaviorSubject } from "rxjs";

export class MapService {

    private map: google.maps.Map;
    private inputBox: google.maps.places.SearchBox;
    private geocoder: google.maps.Geocoder;

    public markers$ = new BehaviorSubject<google.maps.Marker[]>([]);

    private bounds = new google.maps.LatLngBounds();

    constructor(map: google.maps.Map, inputBox: google.maps.places.SearchBox, initialCoordintes: string[]|undefined){
        this.map = map;
        this.map.addListener("click", (event: {latLng: google.maps.LatLng}) => {        
            this.addLatLng(event.latLng);
        });
        
        this.inputBox = inputBox;
        this.inputBox.addListener("places_changed", () => {
            const searchBoxPlaces = this.inputBox.getPlaces();
            if(searchBoxPlaces && searchBoxPlaces.length){
                this.addPlace(searchBoxPlaces[0]);
            }
        });
        this.geocoder = new google.maps.Geocoder();

        if(initialCoordintes && initialCoordintes.length){
            initialCoordintes.forEach((coord)=> {
                this.addLatLng(JSON.parse(coord));
            })
        }
    }

    private addLatLng(latLng: google.maps.LatLng | google.maps.LatLngLiteral){
        this.geocoder.geocode({
            location: latLng,              
        }, (results, status) => {
            if(status === "OK"){
                const place = results?.find(result => result.types.includes("plus_code"));
                
                if(place !== undefined) {
                    this.addPlace(place);
                }

            }
        });
    }

    private addPlace(place: google.maps.places.PlaceResult){
         if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
        }
    
        const marker = new google.maps.Marker({
            position: place.geometry.location,
            map: this.map,
            animation: google.maps.Animation.DROP,
            draggable: true,    
        });

        marker.addListener("click", () => {            
            marker.setMap(null);
            const markers = this.markers$.value;
            markers.splice(markers.indexOf(marker));
            this.markers$.next([...markers]);
        })
    
        if (place.geometry.viewport) {
            this.bounds.union(place.geometry.viewport);
        } else {
            this.bounds.extend(place.geometry.location);
        }

        this.markers$.next([...this.markers$.value, marker]);
        this.map.fitBounds(this.bounds);
    }
}
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { getResponse, MemoryResponse, addAnswersAndSubmit } from "../../api/response";
import { getTopic } from "../../api/topics";
import { uploadMedia } from "../../api/upload";
import { AppContextType } from "../../App";
import { MediaStatus } from "../../components/mediaStatus/mediaStatus";
import { SelectOptions } from "./components/input/select/Select";
import { QuestionType } from "./components/question/typeConfig";
import { SubmitAction, SubmitActionType, SubmitState } from "./components/submitMemory/state/reducer";

export interface QuestionData {
    uuid: string;
    string_id: string;
    description: string;
    description_i18n: {
        [i18n: string]: string
    }
    label: string;
    label_i18n: {
        [i18n: string]: string
    }
    next_item: string | undefined;
    type: {
        uuid: string;
        name: QuestionType;
    },
    options?: SelectOptions[];
    options_i18n?: {
        [i18n: string]: SelectOptions
    }
    answer$: BehaviorSubject<ItemAnswer | undefined>;
    editMode$: Observable<boolean>;
}

export interface ItemValue {
    orderNumber: number,
    value: string,
    uuid?: string
}

type MediaBase = {
    orderNumber: number,
    mime_type: string,
    status?: MediaStatus,
    error?: string,
    description?: string,
    uuid?: string
}

export type ItemMediaWithSrc = MediaBase & {
    src: string
}

type ItemMediaWithDmsId = MediaBase & {
    dmsId: string,
    src: string | undefined
}

export type ItemMedia = ItemMediaWithSrc | ItemMediaWithDmsId;

export type ItemAnswer = {
    values?: ItemValue[],
    media?: ItemMedia[],
    timeStamp: number
}

interface FlatAnswerList {
    [key: string]: ItemAnswer
}

export class MemoryService {
    private _currentIndex = -1;

    public topicId: string | undefined = undefined;
    public responseUuid: string | undefined = undefined;

    private _currentQuestionUuid$: BehaviorSubject<string> = new BehaviorSubject("");
    private _answers: { [uuid: string]: BehaviorSubject<ItemAnswer | undefined> } = {};

    private _processedQuestions: QuestionData[] = [];
    private _mediaUploads = new Set<Promise<string>>();

    public questions$ = new BehaviorSubject<QuestionData[]>([]);
    public completed$ = new BehaviorSubject<boolean>(false);
    public editing$ = new Subject<boolean>();
    public submitted$ = new BehaviorSubject(false);
    public terms$ = new BehaviorSubject<string | undefined>(undefined);

    private subscriptions: Subscription[] = [];

    private get storageKey() {
        return `minne|${this.topicId}|response|${this.responseUuid}`;
    }

    constructor(private appContext: AppContextType) {
        if (typeof window !== "undefined") {
            window.addEventListener("beforeunload", (e) => {
                const stillUploadingMessage = "Vi laster fortsatt opp noen av svarene dine, er du sikker på at du vil forlate siden?";
                if (this._mediaUploads.size) {
                    (e || window.event).returnValue = stillUploadingMessage; //Gecko + IE
                    return stillUploadingMessage; //Gecko + Webkit, Safari, Chrome etc.
                }
            })
        }
    }

    public init(topicId: string, uuid: string | undefined, submitDispatch: React.Dispatch<SubmitAction>) {
        if (topicId !== this.topicId) {
            this.topicId = topicId || "";

            if (uuid) {
                this.responseUuid = uuid;
                this._getAnswers(uuid, submitDispatch);
            } else {
                this.responseUuid = undefined;
                this._getAnswersFromStorage();

                this.subscriptions.push(getTopic(topicId).subscribe(val => {
                    this.setTerms(val);
                    this.setQuestions(val?.items);
                }));
            }
        }
    }

    public unsubscribe() {
        this.subscriptions = this.subscriptions.reduce((prev, cur) => {
            cur.unsubscribe();
            return prev;
        }, [])
    }

    private _getAnswers(uuid: string, submitDispatch: React.Dispatch<SubmitAction>) {
        this.subscriptions.push(getResponse(uuid).subscribe(response => {
            if (response) {
                submitDispatch({
                    type: SubmitActionType.INIT_FROM_RESPONSE,
                    payload: response
                });
             
                if (response.values) {
                    for (const val of response.values) {
                        const answer$ = this.getAnswer(val.topic_item.uuid);
                        const prevAnswer = answer$.value;

                        answer$.next({
                            ...prevAnswer,
                            values: [
                                ...prevAnswer?.values || [],
                                { value: val.value, orderNumber: val.order_number, uuid: val.uuid }
                            ],
                            timeStamp: Date.now(),
                        })
                    }
                }

                if (response.media) {
                    for (const media of response.media) {
                        const answer = this.getAnswer(media.topic_item.uuid);
                        const prevAnswer = answer.value;

                        answer.next({
                            ...prevAnswer,
                            media: [
                                ...prevAnswer?.media || [],
                                {
                                    orderNumber: media.order_by_number,
                                    dmsId: media.dms_id,
                                    description: media.description,
                                    mime_type: media.mime_type || "image/webm",
                                    src: "",
                                    uuid: media.uuid
                                }
                            ],
                            timeStamp: Date.now()
                        })
                    }
                }
            } else {
                alert("Unable to retrieve memory");
            }

            this.subscriptions.push(getTopic(this.topicId!).subscribe(topic => {
                this.setTerms(topic);
                this.setQuestions(topic?.items);
            }));
        }));
    }

    private _getAnswersFromStorage() {
        const storedString = localStorage.getItem(this.storageKey);
        if (storedString) {
            const jsonObject: FlatAnswerList = JSON.parse(storedString);
            for (let key in jsonObject) {
                const answer$ = this.getAnswer(key);
                answer$.next(jsonObject[key]);
            }
        }
    }

    private setTerms(data: { terms_i18n?: { [i18n: string]: string }, terms?: string | { [i18n: string]: string } }) {
        if (!data) {
            return;
        }
        this.subscriptions.push(this.appContext.i18n$.subscribe((i18n) => {
            if (data.terms_i18n) {
                const terms = data.terms_i18n[i18n] || data.terms_i18n[Object.keys(data.terms_i18n)[0]];
                this.terms$.next(terms);
            }
            else if (data.terms) {
                if (typeof data.terms === "object") {
                    const terms = data.terms[i18n] || data.terms[Object.keys(data.terms)[0]];
                    this.terms$.next(terms);
                } else {
                    this.terms$.next(data.terms);
                }
            }
        }));
    }


    private storeLocalAnswers() {
        const flatObject = {} as FlatAnswerList;
        for (let key in this._answers) {
            const answer = this._answers[key].value as ItemAnswer;
            if (answer !== undefined) {
                flatObject[key] = answer;
            }
        }
        localStorage.setItem(this.storageKey, JSON.stringify(flatObject));
    }

    public getCurrentAnswer() {
        return this.getAnswer(this._currentQuestionUuid$.value).getValue();
    }

    public editAnswer(uuid: string) {
        this._currentQuestionUuid$.next(uuid);
        this.editing$.next(true);
    }

    public addAnswerWithText(text: string | string[]) {
        const textArray = Array.isArray(text) ? text : [text];
        const answer: ItemAnswer = {
            values: textArray.map((text, index) => ({
                value: text,
                orderNumber: index
            })),
            timeStamp: Date.now(),
        }
        this.addAnswer(answer);
    }

    public addEmptyAnswer = () => {
        this.addAnswer({
            timeStamp: Date.now()
        })
    }

    public addAnswer(answer: ItemAnswer) {
        if (this._currentQuestionUuid$.value) {
            const answer$ = this.getAnswer(this._currentQuestionUuid$.value);
            answer$.next(answer);

            if (answer.media) {
                answer.media.forEach((mediaItem, index) => {
                    if (!("dmsId" in mediaItem) && mediaItem.status !== MediaStatus.UPLOADING) {

                        mediaItem.status = MediaStatus.UPLOADING;
                        answer$.next({ ...answer });

                        const mediaUpload = uploadMedia(mediaItem);
                        this._mediaUploads.add(mediaUpload);
                        const cleanup = () => this._mediaUploads.delete(mediaUpload);

                        mediaUpload.then((id: string) => {
                            cleanup();
                            mediaItem.status = MediaStatus.PROCESSING;
                            (mediaItem as ItemMediaWithDmsId).dmsId = id;
                            answer$.next({ ...answer });
                            this.storeLocalAnswers();
                        }).catch(error => {
                            cleanup();
                            mediaItem.status = MediaStatus.NOT_UPLOADED;
                            answer$.next({ ...answer });
                        });
                    }
                });
            }

            const currentQuestions = this.questions$.value;

            //Uuid of the last currently displayed question
            const lastUuid = currentQuestions[currentQuestions.length - 1].uuid;

            //If the currently edited uuid is not of the last (edit mode)
            if (lastUuid !== this._currentQuestionUuid$.value) {
                const specifiedNext = this.getanswerDependantNextQuestion();


                //If the edited answer has a specified next question, and it it not displayed
                //we need to reset to that question and start again from there
                if (specifiedNext && !currentQuestions.includes(specifiedNext)) {
                    const index = currentQuestions.findIndex(val => val.uuid === this._currentQuestionUuid$.value);
                    this.questions$.next(currentQuestions.slice(0, index + 1));
                    this.addQuestion(specifiedNext);
                } else if (!this.completed$.value) {
                    //no specific next question, and not completed, go back to last question
                    this._currentQuestionUuid$.next(lastUuid);
                } else {
                    //completed, go to end
                    this.nextQuestion();
                }
            } else {
                //normal sequence, go to next
                this.nextQuestion();
            }

            this.storeLocalAnswers();
            this.editing$.next(false);
        }
    }

    public submit(response: MemoryResponse, lng: string, email?: string) {
        return Promise.all(this._mediaUploads).then(() => {
            return addAnswersAndSubmit(response, this._answers, lng, email).then(() => {
                this.submitted$.next(true);
                localStorage.removeItem(this.storageKey);
            });
        })
    }

    private getAnswer(uuid: string) {
        if (!this._answers[uuid]) {
            this._answers[uuid] = new BehaviorSubject<ItemAnswer | undefined>(undefined);
        }
        return this._answers[uuid];
    }


    private setQuestions(data: any[]) {
        if (data) {
            data.sort((a, b) => a.form_sort_index - b.form_sort_index)
            this._processedQuestions = data.map((
                { uuid,
                    string_id,
                    label,
                    label_i18n,
                    description,
                    description_i18n,
                    type,
                    options,
                    next_item
                }
            ) => {
                const answer$ = this.getAnswer(uuid);
                const inputType = Array.isArray(type) ? type[0] : type;
                const question: QuestionData = {
                    uuid,
                    string_id,
                    label,
                    label_i18n,
                    description,
                    description_i18n,
                    type: { name: inputType?.name as QuestionType, uuid: inputType?.uuid },
                    options: options as SelectOptions[],
                    answer$,
                    next_item,
                    editMode$: this._currentQuestionUuid$.pipe(map(val => val === uuid)),
                };
                return question;
            });

            this.nextQuestion();
        }
    }

    private nextQuestion() {
        const nextQuestion = this.getNextQuestion();
        if (nextQuestion) {
            this.addQuestion(nextQuestion);
        } else {
            this.completed$.next(true);
            this._currentQuestionUuid$.next("completed");
        }
    }

    private addQuestion(question: QuestionData) {
        this.questions$.next([...this.questions$.value, question]);
        this._currentQuestionUuid$.next(question.uuid);
        this._currentIndex = this._processedQuestions.indexOf(question);

        if (this.getAnswer(question.uuid).value) {
            this.nextQuestion();
        }
    }

    private findQuestionAndIndexByName = (name: string) => {
        return this._processedQuestions.find((question, index) => question.string_id === name);
    }

    private getNextQuestion(): QuestionData | null {

        const answerDependantNextQuestion = this.getanswerDependantNextQuestion();

        if (answerDependantNextQuestion) {
            return answerDependantNextQuestion;
        }

        if (this._currentIndex === this._processedQuestions.length - 1) {
            return null;
        }
        return this._processedQuestions[this._currentIndex + 1];
    }

    private getanswerDependantNextQuestion() {
        const currentQuestion = this._processedQuestions.find(val => val.uuid === this._currentQuestionUuid$.value);

        const answer = currentQuestion?.answer$.value;

        if (answer && currentQuestion?.options && answer.values?.length === 1) {
            const selected = answer.values[0].value;
            const nextItemForSelected = currentQuestion.options.find(val => val.uuid === selected)?.next_item;
            if (nextItemForSelected) {
                const nextQuestion = this.findQuestionAndIndexByName(nextItemForSelected);
                if (nextQuestion) {
                    return nextQuestion;
                }
            }
        }
        if (currentQuestion?.next_item) {
            const nextQuestion = this.findQuestionAndIndexByName(currentQuestion.next_item);
            if (nextQuestion) {
                return nextQuestion;
            }
        }

        return null;
    }
}
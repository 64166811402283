import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import "./i18n";
import reportWebVitals from './reportWebVitals';

const isSwedishSite = window.location.hostname.endsWith(".se");
if(isSwedishSite){
  document.title = "minnen";
  const desc: HTMLMetaElement = document.createElement("meta");
  desc.name = "description";
  desc.content = "Skriv minnen";
  document.head.appendChild(desc);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

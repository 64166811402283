import { useContext, useEffect } from "react";
import { getIsCollector } from "../../../../../../api/topics";
import Button from "../../../../../../components/button/Button";
import Dialogue from "../../../../../../components/dialogue/Dialogue";
import Sequencer from "../../../../../../components/sequencer/Sequencer";
import { MemoryContext } from "../../../../memoryWizard";
import ContributorSelector from "./ContributorSelector";

import { useApiWithArgs } from "../../../../../../state/hooks/useApi/useApi";
import { SubmitActionType } from "../../state/reducer";
import React from "react";
import { useObservable } from "../../../../../../state/hooks/useObservable/useObservable";
import PsaudoInput from "./PsaudoInput";

import styles from "./styles.module.scss";
import useAuthorString, { TranslationKey } from "./translationConfig";
import { AppContext } from "../../../../../../App";

const AuthorSelector = () => {
    const memoryContext = useContext(MemoryContext);
    const appContext = useContext(AppContext);
    const state = memoryContext.submitState!;
    const dispatch = memoryContext.submitDispatch!;

    const Question = () => {
        const authorIsSet = useObservable(state.authorIsSet$);
        const isCollector = useApiWithArgs(getIsCollector, [memoryContext.service?.topicId]);

        const authorQuestion = useAuthorString(TranslationKey.AUTHOR_QUESTION);
        const defaultQuestion = [authorQuestion];
    
        const collectorPart = useAuthorString(TranslationKey.COLLECTOR_INFO);
        const question = isCollector ? [...defaultQuestion, collectorPart] : defaultQuestion;

        const Answer = () => {
            const nameSelected = useObservable(state.nameSelected$);
            const psaudoSelected = useObservable(state.psaudoSelected$);
            const collectorSelected = useObservable(state.collectorSelected$);

            const nameLabel = useAuthorString(TranslationKey.USE_NAME);
            const psaudoLabel = useAuthorString(TranslationKey.USE_PSAUDO);
            const collectorLabel = useAuthorString(TranslationKey.USE_CONTRIBUTOR);
        
            const options = [
                <Button
                    className={nameSelected? styles.accepted : styles.submit}
                    label={nameLabel}
                    onClick={() => dispatch({type: SubmitActionType.TOGGLE_USE_NAME})}
                    key="name"
                    hidden={psaudoSelected || collectorSelected}
                />,
                <Button
                    className={psaudoSelected? styles.accepted : styles.submit}
                    label={psaudoLabel}
                    onClick={() => dispatch({type: SubmitActionType.TOGGLE_USE_PSAUDO})}
                    key="psaudo"
                    hidden={nameSelected || collectorSelected}
                />,
                <Button 
                    className={collectorSelected? styles.accepted : styles.submit}
                    label={collectorLabel}
                    onClick={() => dispatch({type: SubmitActionType.TOGGLE_USE_CONTRIBUTOR})}
                    key="collector"
                    hidden={!isCollector || nameSelected || psaudoSelected}
                />
            ];
        
            return (
                <Sequencer elements={options} skipAnimation={!!authorIsSet} />
            )
        };

        return (
            <Dialogue questions={question} answer={React.createElement(Answer)} animate={!authorIsSet && !isCollector} />
        )
    }

    const PsaudoOrCollector = () => {
        const psaudoSelected = useObservable(state.psaudoSelected$);
        const collectorSelected = useObservable(state.collectorSelected$);

        if(psaudoSelected){
            return(<PsaudoInput />)
        } else if(collectorSelected){
            return(<ContributorSelector />)      
        } else return (<></>)
    }

    const isLoggedIn = useObservable(appContext.isLoggedIn$)

    useEffect(() => {
        if(!isLoggedIn){
            dispatch({type: SubmitActionType.USE_PSAUDO})
        }
    },[isLoggedIn])

    return (
        !isLoggedIn 
            ? <PsaudoInput />
            : <>
                <Question />
                <PsaudoOrCollector />
            </>
    );
}

export default AuthorSelector;
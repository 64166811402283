import { useEffect, useRef, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { useObservable } from "../useObservable/useObservable";

const useMemoizeArgs = (args: any[]) => {

    const previousRef = useRef<any[]>();
    const previous = previousRef.current || [];

    const isEqual = args?.every((val, index) => previous?.[index] === args[index]);

    useEffect(() => {
        if (!isEqual) {
            previousRef.current = args;
        }
    });

    return isEqual ? previous as any[] : args;
} 

export const useApiWithArgs = <T>(request: (...params: any) => BehaviorSubject<T>, args: any[]) => {
    
    const [observable, setObservable] = useState<BehaviorSubject<T>>();
    const data = useObservable(observable);
    const memoizedArgs = useMemoizeArgs(args);

    useEffect(() => {
        const observable = request(...memoizedArgs);
        setObservable(observable);
    },[request, memoizedArgs])

    return data;
}

export const useApi = <T>(request: () => BehaviorSubject<T>) => {
    const [observable, setObservable] = useState<BehaviorSubject<T>>();
    const data = useObservable(observable);

    useEffect(() => {
        const observable = request();
        setObservable(observable);
    },[request])

    return data;
}
import React, {useContext, useEffect, useState} from "react";

import { AppContext } from "../../../../App";
import { useObservable } from "../../../../state/hooks/useObservable/useObservable";
import { MemoryContext } from "../../memoryWizard";
import LoginDialogue from "./parts/login/LoginDialogue";

import Terms from "./parts/terms/Terms";

import AuthorSelector from "./parts/authorSelector/AuthorSelector";

import SubmitUserInfo, { FormUserInfo } from "../../../../components/userInfo/SubmitUserInfo";
import useHasUserInfo from "./state/hasUserInfo";
import PublishDialogue from "./parts/publish/PublishDialogue";
import CompletedDialogue from "./parts/completed/CompletedDialogue";
import { SubmitActionType, SubmitState } from "./state/reducer";
import { combineLatest, Subscription } from "rxjs";

type FromProgressState = {
    termsAccepted: boolean,
    publish: boolean | null | undefined,
    authorIsSet: boolean | null,
    collectorSelected: boolean,
    email: string | undefined,
    userInfo: FormUserInfo | undefined
}

const useSubmitFormState = (state: SubmitState) => {
    
    const [progress, setProgress] = useState<FromProgressState>();

    useEffect(() => {
        let subscription: Subscription|undefined;
        if(state.termsAccepted$){
            subscription = combineLatest({
                termsAccepted: state.termsAccepted$,
                publish: state.publish$,
                authorIsSet: state.authorIsSet$,
                collectorSelected: state.collectorSelected$,
                email: state.email$,
                userInfo: state.userInfo$
            }).subscribe(data => {
                setProgress(data)
            })
        }
        return () => {
            subscription?.unsubscribe();
        }
    }, [state])
    
    return progress;
}

const SubmitForm = () => {
    const memoryContext = useContext(MemoryContext);
    const state = memoryContext.submitState!;
    const dispatch = memoryContext.submitDispatch;

    const progress = useSubmitFormState(state);

    const terms = useObservable(memoryContext.service?.terms$)
 
    const publishIsSet = progress?.publish !== undefined && progress?.publish !== null;
    const userInfoIsSet = useHasUserInfo(progress?.userInfo);

    const userInfoCallback = (userInfo: FormUserInfo) => {
        dispatch?.({type: SubmitActionType.SET_USER_INFO, payload: userInfo});
    }
 
    const isCompleted = (
        (!terms || progress?.termsAccepted) 
        && publishIsSet && progress?.authorIsSet 
        && (progress?.collectorSelected || !!progress.email || userInfoIsSet));

    return (
        <>
            { terms && <Terms terms={terms} />}
            { (!terms || progress?.termsAccepted) && <PublishDialogue /> }
            { publishIsSet && <AuthorSelector /> }
            { (
                publishIsSet 
                && progress?.authorIsSet 
                && !progress?.collectorSelected 
                && !progress?.email
            ) && <SubmitUserInfo infoCallback={userInfoCallback} initialState={progress?.userInfo}/>}
            { isCompleted &&  <CompletedDialogue />}
        </>
    )
};


const SubmitDialogue = () => {

    const appContext = useContext(AppContext);
    const memoryContext = useContext(MemoryContext);
    const state = memoryContext.submitState!;

    const isLoggedIn = useObservable(appContext.isLoggedIn$);
    const emailIsSet = !!useObservable(state.email$);
    const isLoggedInOrEmailSet = isLoggedIn || emailIsSet;

    return (
        isLoggedInOrEmailSet ? <SubmitForm /> : <LoginDialogue />
    );
};

export default SubmitDialogue;
import { useEffect, useState } from "react";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";


export const useObservable = <T>(observable: BehaviorSubject<T>|ReplaySubject<T>|Observable<T>|undefined) => {
    const [val, setVal] = useState<T>(observable instanceof BehaviorSubject ? observable.getValue() : null as unknown as T);

    useEffect(() => {
        const subscription = observable?.subscribe((newVal)=> {
            setVal(newVal);
        });
        return () => subscription?.unsubscribe();
    }, [observable]);

    return val;
}
import { useState } from "react";
import { useLocation } from "react-router";
import cn from "classnames";
import Button from "../../../../../../components/button/Button";
import Dialogue from "../../../../../../components/dialogue/Dialogue";
import Sequencer from "../../../../../../components/sequencer/Sequencer";

import styles from "./styles.module.scss";

import { useQuery } from "../../../../../../state/hooks/utils";
import { loginAndRedirect } from "../../../../../oauth2/utils";
import { LoginKey, useTranslatedString} from "./translationConfig";
import RegisterEmail from "./RegisterEmail";


const LoginDialogue = () => {
    
    const [showRegister, setShowRegister] = useState(false);
    const currentUrl = useLocation().pathname;
    const query = useQuery().toString();
    
    const loginClicked = () => {
        loginAndRedirect(`${currentUrl}?${query}`);
    };

    const registerEmail = () => {
        setShowRegister(!showRegister);
    };

    const line1 = useTranslatedString(LoginKey.loginInfo1);
    const line2 = useTranslatedString(LoginKey.loginInfo2);
    const line3 = useTranslatedString(LoginKey.loginInfo3);
    const loginLabel = useTranslatedString(LoginKey.loginLabel);
    const registerEmailLabel = useTranslatedString(LoginKey.registerEmailLabel);

    const questions = [
        line1,
        line2,
        line3
    ];

    const loginButtons = [
        <Button 
            className={cn(styles.answer, styles.button, showRegister && styles.hidden)} 
            label={loginLabel}
            onClick={loginClicked} 
            key="login"
        />,
        <Button 
            className={cn(styles.answer, styles.button, showRegister && styles.selected)} 
            label={registerEmailLabel}
            onClick={registerEmail} 
            key="email"
        />
    ];

    const answerPart = (
        <Sequencer elements={loginButtons} />
    );
    
    return (
        <div className={styles.loginDialogue}>
            <Dialogue questions={questions} answer={answerPart} animate={!showRegister}/>            
            {showRegister && <RegisterEmail />}
        </div>
    );
}

export default LoginDialogue;
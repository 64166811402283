import { BehaviorSubject } from "rxjs";
import { MediaRecorderService } from "./mediaRecorderService";

export enum MediaMode {
    AUDIO,
    VIDEO
};

export class MediaControllerService {

    constructor(private recorder: MediaRecorderService){    
    }

    private _mediaMode = new BehaviorSubject<MediaMode>(MediaMode.VIDEO);
    public get mediaMode(){
        return this._mediaMode;
    }

    public toggleMediaMode() {
        this._mediaMode.next(this._mediaMode.value === MediaMode.VIDEO ? MediaMode.AUDIO : MediaMode.VIDEO);
    }
    
    private _hasCaptured = new BehaviorSubject(false);
    public get hasCaptuerd(){
        return this._hasCaptured;
    }

    public setHasCaptured(has: boolean = true){
        this._hasCaptured.next(has);
    }
        
    public toggleInputSource() { 
        const currentIndex = this.recorder.inputSourceIndex$.value;
        const nextIndex =  currentIndex >= this.recorder.inputSourceArray.length - 1 ? 0 : currentIndex + 1;
        this.recorder.setInputSourceIndex(nextIndex);
    }
}
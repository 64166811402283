import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Locize from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(Locize)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "nb-NO",
        debug: process.env.NODE_ENV === "development",

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        saveMissing: true,
        backend: {
            projectId: "e54181f0-c2de-4f0b-90ce-84d5df542424",
            apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
            referenceLng: "nb-NO",
            fallbackLng: "nb-NO",
            allowedAddOrUpdateHosts: ["localhost"],
        },
    });

export default i18n;

import React, { useState } from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import Button from "../../../../../components/button/Button";
import SubmitButton from "../../../../../components/button/SubmitButton";
import Sequencer from "../../../../../components/sequencer/Sequencer";
import { useLocizeString } from "../../../../../state/hooks/utils";
import { MemoryContext } from "../../../memoryWizard";
import { textValuesFromCurrentAnswer } from "../utils";

import styles from "./styles.module.scss";

const DateRange = () => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [fromValid, setFromValid] = useState<boolean>(false);
    const [toValid, setToValid] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<string>();

    const fromRef = useRef<HTMLInputElement>(null);
    const toRef = useRef<HTMLInputElement>(null);

    const memoryContext = useContext(MemoryContext);
    const initialDates = textValuesFromCurrentAnswer(memoryContext.service);

    const fromErrorText = useLocizeString("memoryForm", "fromDateError", "Ugyldig fra-dato");
    const toErrorText = useLocizeString("memoryForm", "toDateError", "Ugyldig til-dato");

    const [focus, setFocus] = useState(false);
    
    useEffect(()=> {
        if(focus){
            fromRef.current?.focus();
        }
    }, [focus])

    useEffect(() => {
        setIsValid(fromValid && toValid);
    },[fromValid, toValid])

    const send = () => {
        if(isValid){
            const dates = [fromRef.current!.value, toRef.current!.value]; 
            memoryContext.service?.addAnswerWithText(dates);
        }
        else {
            memoryContext.service?.addEmptyAnswer();
        }
    }

    const fromChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFromValid(event.target.value !== "" && event.target.checkValidity());
        if(fromValid){
            setFromDate(event.target.value);
        }
    }

    const toChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToValid(event.target.value !== "" && event.target.checkValidity());
    }

    const submitHandler = (event: React.FormEvent) => {
        event.preventDefault();
        send();
    }

    const dateSelector = (
        <div 
            className={styles.dateRangeWrapper}
            onAnimationEnd={()=>{setFocus(true)}}
            key="dateSelector"
        >   
            <div className={styles.dateRange} >
                <input 
                    type="date" 
                    ref={fromRef} 
                    onInput={fromChanged}
                    defaultValue={initialDates?.[0]}
                />
                <span> - </span>
                <input 
                    type="date" 
                    ref={toRef} 
                    onInput={toChanged} 
                    min={fromDate}
                    defaultValue={initialDates?.[1]}
                />

                <Button 
                    materialIcon="keyboard_return"
                    type="submit"
                />
            </div>
            <div className={styles.errorRow} >
                <div>{!fromValid && fromErrorText}</div>
                <div></div>
                <div>{!toValid && toErrorText}</div>
            </div>
        </div>
    );

    const elements = [
        dateSelector,
        <SubmitButton type="button" isValid={isValid} onClick={send} key="submit"/>
    ];

    return (
        <form className={styles.form} onSubmit={submitHandler}>
            <Sequencer elements={elements} /> 
        </form>
    );
}

export default DateRange;
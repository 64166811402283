import cn from "classnames";
import { useContext } from "react";
import { AppContext } from "../../App";

import Button from "../../components/button/Button";
import { useObservable } from "../../state/hooks/useObservable/useObservable";
import MemoizedMemoryWizard from "../memoryWizard/memoryWizard";

import styles from "./styles.module.scss";

interface ConversationProps {
    topicId: string,
    showConversation: boolean,
    setShow: (show: boolean) => void
};

const Conversation = ({topicId: topicId, showConversation, setShow}:ConversationProps) => {
    
    const appContext = useContext(AppContext);
    const menuVisible = useObservable(appContext.menuVisible$);
    
    return (
        <div className={cn(styles.conversationWrapper, menuVisible && styles.conversationWrapper_menuVisible)}>
            <div className={cn(styles.conversationContainer, showConversation && styles.conversationContainer_active)}>
                <Button 
                    materialIcon={showConversation ? "close": "question_answer"} 
                    className={styles.chatButton}
                    onClick={() => setShow(!showConversation)}
                />
                <div className={styles.conversation}>
                    {topicId && <MemoizedMemoryWizard topic_id={topicId}/>}
                </div>     
            </div>
        </div>
    );
};

export default Conversation;
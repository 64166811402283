import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../../../../App";
import Button from "../../../../../components/button/Button";
import SubmitButton from "../../../../../components/button/SubmitButton";
import Sequencer from "../../../../../components/sequencer/Sequencer";
import { useObservable } from "../../../../../state/hooks/useObservable/useObservable";
import { MemoryContext } from "../../../memoryWizard";
import { textValuesFromCurrentAnswer } from "../utils";
import styles from "./styles.module.scss";

export type SelectOptions = {
    description: string;
    description_i18n: {
        [i18n: string]: string
    };
    label: string;
    label_i18n: {
        [i18n: string]: string
    };
    next_item: string | undefined;
    uuid: string;
};

const Select = (props: { items: SelectOptions[], multiselect?: boolean }) => {

    const {items, multiselect} = props;

    const memoryContext = useContext(MemoryContext);
    const appContext = useContext(AppContext);
    const i18n = useObservable(appContext.i18n$);

    const [selected, setSelected] = useState(textValuesFromCurrentAnswer(memoryContext.service));
    const initialRender = useRef(true);

    const submit = useCallback(() => {
        if(selected){
            memoryContext.service?.addAnswerWithText(selected);
        }
        else {
            memoryContext.service?.addEmptyAnswer();
        }
    },[memoryContext, selected]);
    
    useEffect(()=>{
        if(initialRender.current){
            initialRender.current = false;
        }
        else if (selected && !props.multiselect) {
            submit();
        }
    },[selected, props.multiselect, submit])

    const toggleSelected = (uuid: string) => {
        const currentIndex = selected ? selected.indexOf(uuid) : -1;

        if(multiselect){
            if(currentIndex > -1){
                selected?.splice(currentIndex, 1);
                setSelected([...selected!]);
            } else {
                setSelected(selected ? [...selected, uuid] : [uuid]);
            }
        } else {
            setSelected(!!currentIndex ? [uuid] : undefined);
        }
    };

    const isSelected = (uuid: string) => selected ? selected.indexOf(uuid) > -1 : false;

    const elements = items ? [
            ...items.map((val,index) => (
                <Button
                    key={val.label}
                    className={cn(styles.selectButton, isSelected(val.uuid) && styles.selected)}
                    materialIcon={isSelected(val.uuid) ? "check" : undefined}
                    label={val.label_i18n?.[i18n] || val.label}
                    onClick={() => toggleSelected(val.uuid)}
                />
            )),
            <SubmitButton
                isValid={!!selected?.length}
                onClick={submit}
                key="submit"
            />
    ] : [
        <SubmitButton
            isValid={!!selected?.length}
            onClick={submit}
            key="submit"
        />
    ];

    return (
        <div className={styles.container}>
            <Sequencer elements={elements} skipAnimation={!!selected} />
        </div>
    )
}

export default Select;
import { useLocizeString } from "../../../../../../state/hooks/utils";
import { TranslationConfig } from "../../translationConfig";

export enum TranslationKey {
    TERMS_INFO,
    SHOW_TERMS,
    ACCEPT_TERMS,
    TERMS_HEADER,
    ACCEPT_AND_CLOSE
}

const translationConfigs: {[key in TranslationKey]: TranslationConfig} = {
    [TranslationKey.TERMS_INFO]: {
        key: "termsInfo",
        fallback: "For å sende inn minnet må du godta villkårene for innsamlingen"
    },
    [TranslationKey.SHOW_TERMS]: {
        key: "showTermsLabel",
        fallback: "Vis villkår"
    },
    [TranslationKey.ACCEPT_TERMS]: {
        key: "acceptTermsLabel",
        fallback: "Aksepter villkår"
    },
    [TranslationKey.TERMS_HEADER]: {
        key: "termsHeader",
        fallback: "Villkår"
    },
    [TranslationKey.ACCEPT_AND_CLOSE]: {
        key: "acceptAndCloseTerms",
        fallback: "Lukk og Aksepter"
    },
}

const useTranslatedString = (key: TranslationKey) => {
    const config = translationConfigs[key];
    return useLocizeString("terms", config.key, config.fallback);
}

export default useTranslatedString;
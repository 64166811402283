import { useContext } from 'react';
import { useObservable } from '../../../../state/hooks/useObservable/useObservable';
import { QuestionData } from '../../memoryService';
import { MemoryContext, MemoryContextType } from '../../memoryWizard';
import { getAnswerComponent, getInputComponent } from './typeConfig';
import styles from './styles.module.scss';
import EmptyAnswer from '../answer/EmptyAnswer';
import Dialogue from '../../../../components/dialogue/Dialogue';
import { useLocizeString } from '../../../../state/hooks/utils';
import { stripHtml } from 'string-strip-html';
import { AppContext } from '../../../../App';
import Button from '../../../../components/button/Button';

const cleanString = (htmlString: string | undefined) => {
    if(htmlString){
        return stripHtml(htmlString, {ignoreTags: ["br"]}).result
    }
    return "";
};

const questionLinesFromQuestion = (data: QuestionData, i18n: string) => {
    let lineArray: string[] = [];

    if(data.label){
        const label = data.label_i18n?.[i18n] || data.label;
        const text = cleanString(label);
        lineArray = text.split("<br>").filter(val => /\S/.test(val));
    }
    if(data.description){
        const description = data.description_i18n?.[i18n] || data.description;
        const text = cleanString(description);
        lineArray = [...lineArray, ...text.split("<br>").filter(val => /\S/.test(val))];
    }
    return lineArray;
}

const Question = (props: {data: QuestionData, animate?: boolean}) => {

    const {data, animate} = {...props};
    const memoryContext = useContext(MemoryContext) as MemoryContextType;
    const appContext = useContext(AppContext);
    const i18n = useObservable(appContext.i18n$);
    
    const lineArray: string[] = questionLinesFromQuestion(data, i18n);
    
    const answer = useObservable(data.answer$);
    const edit = useObservable(data.editMode$);

    const secondsAgoLabel = useLocizeString("memoryForm", "secondsAgoLabel", "Noen sekunder siden");
    const minuitesAgoLabel = useLocizeString("memoryForm", "minuitesAgoLabel", "Noen minutter siden");
    const editLabel = useLocizeString("memoryForm", "editLabel", "Endre");

    const doEdit = () => {
        memoryContext.service.editAnswer(data.uuid);
    }

    const timeString = () => {
        if(answer === undefined){
            return "";
        }

        const timeAgo = Date.now() - answer.timeStamp;

        if(timeAgo < 6000){
            return secondsAgoLabel;
        } else if(timeAgo < 6000*60){
            return minuitesAgoLabel;
        } else {
            return new Date(answer.timeStamp).toLocaleTimeString();
        }
    }

    const answerPart = (
        edit 
            ? getInputComponent(props.data)
            : (
                <div role="button" onClick={doEdit}>
                    {answer && (answer.values || answer.media)
                        ? getAnswerComponent(props.data)
                        : <EmptyAnswer />
                    }
                    <div className={styles.editRow}>
                        {timeString()} - <Button onClick={doEdit} label={editLabel} />
                    </div>
                </div>
            )
    );

    return (
        <Dialogue questions={lineArray} answer={answerPart} animate={animate} />
    )
}

export default Question;
import { useContext } from "react";
import Button from "../../../../../components/button/Button";
import { useObservable } from "../../../../../state/hooks/useObservable/useObservable";
import { useLocizeString } from "../../../../../state/hooks/utils";
import { MediaRecorderContext } from "../mediaRecorder";
import { RecordingState } from "../mediaRecorderService";

import styles from './styles.module.scss';

const RecordControls = () => {
    
    const context = useContext(MediaRecorderContext);
    const state = useObservable(context.recorder?.recorderState$);

    const startLabel = useLocizeString("mediaRecorder", "startRecordingLabel", "Spill inn svar");
    const stopLabel = useLocizeString("mediaRecorder", "stopRecordingLabel", "Stopp innspilling");

    const save = () => {
        context.recorder?.stopRecording()
   }

    const Controls = () => {
        switch(state) {
            case RecordingState.READY:
                return (
                    <Button key="start" className={styles.record} label={startLabel} materialIcon="radio_button_checked" onClick={() => context.recorder?.toggleRecording()} />
                );
            case RecordingState.RECORDING:
            case RecordingState.PAUSED:  
                return (
                    <Button
                        materialIcon="stop"
                        label={stopLabel}
                        onClick={save}/>
                );
            default:
                return (<div></div>); 
        }
    }

    return (
        <div className={styles.recordBar}>
            <Controls />              
        </div>  
    )
}

export default RecordControls;
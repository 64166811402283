import { useContext, useEffect } from "react";
import { useObservable } from "../useObservable/useObservable";
import { AppContext } from "../../../App";
import { getUserInfo } from "../../../api/user";
import { Subscription } from "rxjs";

const useUserInfo = () => {
    const appContext = useContext(AppContext);
    const isLoggedIn = useObservable(appContext?.isLoggedIn$);

    useEffect(() =>{
        let subscription: Subscription | undefined;

        if(!isLoggedIn){
            getUserInfo().next(undefined);
        } else {
            subscription = getUserInfo().subscribe(appContext.userInfo$);
        }
        return(() => subscription?.unsubscribe());
    },[isLoggedIn, appContext])
}

export default useUserInfo;
import { useLocizeString } from "../../../../../../state/hooks/utils";
import { TranslationConfig } from "../../translationConfig";

export enum TranslationKey {
    AUTHOR_QUESTION,
    COLLECTOR_INFO,
    USE_NAME,
    USE_PSAUDO,
    USE_CONTRIBUTOR,
    CONTRIBUTOR_PROMT,
    PSAUDO_PROMT,
    PSAUDO_PLACEHOLDER,
    EMAIL_INFO_NAME,
    EMAIL_ABOUT_NAME,
    EMAIL_NAME_PLACEHOLDER
}

const authorConfig: {[key in TranslationKey]: TranslationConfig} = {
    [TranslationKey.AUTHOR_QUESTION]: {
        key: "author_question",
        fallback: "Skal vi bruke navnet ditt til å signere innsamlingen din, eller vil du legge inn et psaudonym (dekknavn/alias)?"
    },
    [TranslationKey.COLLECTOR_INFO]: {
        key: "collector_info",
        fallback: "Som innsamler for ditt museeum kan du også velge en av museets meddelere"
    },
    [TranslationKey.USE_NAME]: {
        key: "use_name_label",
        fallback: "Bruk navn"
    },
    [TranslationKey.USE_PSAUDO]: {
        key: "use_psaudo_label",
        fallback: "Pseudonym"
    },
    [TranslationKey.USE_CONTRIBUTOR]: {
        key: "use_contributor_label",
        fallback: "Meddeler"
    },
    [TranslationKey.CONTRIBUTOR_PROMT]: {
        key: "contributor_select_promt",
        fallback: "Velg meddeler"
    },
    [TranslationKey.PSAUDO_PROMT]: {
        key: "psaudo_input_promt",
        fallback: "Hva vil du bruke som psaudonym?"
    },
    [TranslationKey.PSAUDO_PLACEHOLDER]: {
        key: "psaudo_input_placeholder",
        fallback: "Skriv ditt psaudonym"
    },
    [TranslationKey.EMAIL_INFO_NAME]: {
        key: "email_info_name",
        fallback: "Hvilket navn ønsker du å signere innsendelsen med? Bruk gjerne ditt fulle navn, men du kan også skrive et psaudonym/alias"
    },
    [TranslationKey.EMAIL_ABOUT_NAME]: {
        key: "email_about_name",
        fallback: "Navnet vil være synlig for museet, og for andre dersom du har valgt at minnet skal være synlig for alle"
    },
    [TranslationKey.EMAIL_NAME_PLACEHOLDER]: {
        key: "email_name_placeholder",
        fallback: "Skiv inn navn"
    },
}

const useAuthorString = (key: TranslationKey) => {
    const config = authorConfig[key];
    return useLocizeString("authorInput", config.key, config.fallback);
}

export default useAuthorString;
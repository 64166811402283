import { BrowserRouter as Router } from 'react-router-dom';

import styles from "./styles.module.scss";
import React, { useEffect } from 'react';
import Routes from './routing/Router';
import { BehaviorSubject } from 'rxjs';
import { isLoggedIn } from './packages/oauth2/utils';
import i18n from "./i18n";
import { UserInfo } from './api/user';
import useUserInfo from './state/hooks/useUserInfo/useUserInfo';

export type AppContextType = {
    isLoggedIn$: BehaviorSubject<boolean>,
    i18n$: BehaviorSubject<SiteLng>,
    menuVisible$: BehaviorSubject<boolean>,
    userInfo$: BehaviorSubject<UserInfo|undefined>,
}

export enum SiteLng {
    no = "no",
    ny = "ny",
    en = "en",
    sv = "sv"
}

const lng = i18n.language;

const appContext: AppContextType = {
    isLoggedIn$: isLoggedIn(),
    i18n$: new BehaviorSubject(lng as SiteLng),
    menuVisible$: new BehaviorSubject<boolean>(false),
    userInfo$: new BehaviorSubject<UserInfo|undefined>(undefined)
};

export const AppContext = React.createContext<AppContextType>(appContext);

export const App = () => {

    useUserInfo();

    useEffect(() => {
        const languageListener = (lng: string) => {
            if(lng === "nb-NO"){
                lng = SiteLng.no;
            } else if(lng === "ny-NO"){
                lng = SiteLng.ny;
            }
            appContext.i18n$.next(lng as SiteLng);
        };
        i18n.on("languageChanged", languageListener);
        return () => i18n.off("languageChanged", languageListener);
    }, []);

    return (
        <AppContext.Provider value={appContext}>
            <div className={styles.appContainer}>
                <Router>            
                    <Routes />
                </Router>
            </div>
        </AppContext.Provider>
    );
};
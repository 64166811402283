import { useLocizeString } from "../../../../state/hooks/utils";
import AnswerPart from "./AnswerPart";

import styles from "./styles.module.scss";

const EmptyAnswer = () => {
    const skipText = useLocizeString("memoryForm","skip","Hopp over");

    return (
        <div className={styles.answerContainer}>
            <AnswerPart text={skipText} className={styles.skipped}/>
        </div>
    )
}

export default EmptyAnswer;
import { useEffect, useRef } from "react";
import { useState } from "react"
import { usePrevious } from "../../state/hooks/utils";

const useSequence = (elementList: JSX.Element[], skipAnimation?: boolean) => {
    const [sequence, setSequence] = useState<JSX.Element[]>();
    const prevSequence = usePrevious(sequence);
    const prevLength = useRef<number>();

    useEffect(() => {
        prevLength.current = prevSequence?.length;
    }, [prevSequence])

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined;

        const addElement = (index: number) => {
            const newPortion = elementList.slice(0, index);
            setSequence(newPortion);
        
            if (index < elementList.length) {
                const nextIndex = index + 1;
                timeout = setTimeout(() => addElement(nextIndex), 400);
            }
        }
        if(skipAnimation || prevLength.current === elementList.length){
            setSequence(elementList);
        } else {
            addElement(1);
        }

        return () => {
            if(timeout){
                clearTimeout(timeout);
            }
        }
    },[elementList, skipAnimation]);

    return sequence;
}

export default useSequence;
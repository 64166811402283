import React from "react"
import { Route, Switch } from "react-router"
import FrontPage from "../packages/frontPage/FrontPage"
import Oauth2Router from "../packages/oauth2/routes"
import Topic from "../packages/topic/Topic"
import ResponseRouter from "../packages/response/routes"
import MyPageRouter from "../packages/user/routes"

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <FrontPage />
            </Route>
            <Route path="/myPage">
                <MyPageRouter />
            </Route>
            <Route path="/oauth2">
                <Oauth2Router />
            </Route>
            <Route path="/response">
                <ResponseRouter />
            </Route>
            <Route path="/:name">
                <Topic />
            </Route>
        </Switch>
    )
}

export default Routes;
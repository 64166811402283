import { BehaviorSubject, combineLatest, of } from "rxjs"
import { map } from "rxjs/operators";
import { ItemAnswer, ItemMedia } from "../../../memoryService";
import { MemoryContextType } from "../../../memoryWizard";

export enum MediaType {
    VIDEO,
    IMAGE,
    TEXT
}

type MediaBase = {
    mime_type: string,
}

type MediaWithDmsId = MediaBase & {
    src: string | undefined,
    dmsId: string,
}

type MediaWithSrc = MediaBase & {
    src: string
}

export type MediaEntry = MediaWithSrc | MediaWithDmsId;

export type InputPart = {
    media?: MediaEntry,
    text?: string,
    uuid?: string
}

export class TextInputService {
    public parts$ = new BehaviorSubject<InputPart[]>([]);
    public recorderEnabled$ = new BehaviorSubject<boolean>(false);
    public editPartIndex$ = new BehaviorSubject<number>(0);

    public hasInput$ = new BehaviorSubject<boolean>(false);
    private hasText$ = new BehaviorSubject<boolean>(false);

    constructor(private memoryContext: MemoryContextType){
        combineLatest([
            this.parts$.pipe(map(val => {
                return val.length > 1
            })),
            this.hasText$
        ]).pipe(
            map((val) => {
                return val.some(value => value === true)
            }),
        ).subscribe(this.hasInput$);

        const answer = memoryContext.service.getCurrentAnswer();

        const sortedTextAndMediaList = answer 
            ? answer.values || answer.media
                ? answer.media && answer.values
                    ? [...answer.values, ...answer.media].sort((a,b) => a.orderNumber - b.orderNumber)
                    : answer.media 
                        ? answer.media
                        : answer.values
                : undefined
            : undefined;
        ;
        
        const parts: InputPart[] = sortedTextAndMediaList && sortedTextAndMediaList.length 
            ? sortedTextAndMediaList.map((val,index) => {
                if("dmsId" in val || "src" in val){
                    const mediaSrc = "dmsId" in val ? {dmsId: val.dmsId, src: val.src} : {src: val.src};
                    return {
                        media: {mime_type: val.mime_type, ...mediaSrc},
                        text: val.description,
                        uuid: val.uuid
                    }
                } else {
                    return {
                        text: val.value,
                        uuid: val.uuid
                    }
                }     
            }) 
            : [{}];

        this.parts$.next(parts);
        this.editPartIndex$.next(parts.length-1);
    }

    public addTextToPart(partIndex: number, value: string, forceSubmit?: boolean){
        const parts = this.parts$.value;

        if(value || parts[partIndex].media){
            parts[partIndex].text = value;

            if(partIndex === parts.length -1){
                parts.push({})
            }
            this.parts$.next([...parts]);
            this.editPartIndex$.next(parts.length-1);

            if(forceSubmit){
                this.submit();
            }
        } else {
            const filtered = parts.filter((val, index) => index !== partIndex);
            this.parts$.next(filtered)

            if(partIndex >= parts.length-1){
                this.submit();
            } else {
                this.editPartIndex$.next(filtered.length - 1);
            }
        }
    }

    public editPart(partIndex: number){
        const parts = this.parts$.value; 
        const lastPart = parts[parts.length-1];

        if(partIndex != parts.length-1){
            this.parts$.next(parts.filter(part => (part.text || part.media)));
        }

        this.editPartIndex$.next(partIndex);
    }
    
    public addMediaToEditedPart(mime_type: string, src: string) {
        const partIndex = this.editPartIndex$.value;
        this.parts$.value[partIndex].media = {src, mime_type};
        this.parts$.next([...this.parts$.value]);
        this.closeRecorder();
    }

    public removeMediaFromPart(partIndex: number){
        delete this.parts$.value[partIndex].media;
        this.editPartIndex$.next(partIndex);
        this.parts$.value[partIndex].uuid = undefined;
        this.parts$.next([...this.parts$.value]);
    }

    public setHasText(has: boolean){
        this.hasText$.next(has);
    }

    public closeRecorder = () => {
        this.recorderEnabled$.next(false);
    }
    
    public toggleRecorder(){
        this.recorderEnabled$.next(!this.recorderEnabled$.getValue());
    }

    public submit(){
        const answer = this.parts$.value.reduce(partsToAnswer, {} as ItemAnswer);
        this.memoryContext.service.addAnswer(answer);
        this.clear();
    }

    private clear(){
        this.parts$.next([]);
        this.recorderEnabled$.next(false);
    }
}

const partsToAnswer = (answer: ItemAnswer, part: InputPart, index: number) => {
    if(part.media) {
        const mediaEntry:ItemMedia = { 
            ...part.media, 
            description: part.text, 
            orderNumber: index, 
            uuid: part.uuid,
            mime_type: part.media.mime_type
        };
        const prevMediaArray = answer.media || [];
        const newMediaArray = [...prevMediaArray, mediaEntry]; 
        const newAnswer: ItemAnswer = {...answer, media: newMediaArray};
        return newAnswer;
    }
    const prevValues = answer.values || [];
    const newValuesArray = part.text ? [...prevValues, {value: part.text, orderNumber: index, uuid: part.uuid}] : answer.values;
    const newAnswer: ItemAnswer = {...answer, values: newValuesArray, timeStamp: Date.now()};
    return newAnswer;
};



import React, { useContext, useEffect, useState } from "react";
import { postUserInfo, UserInfo } from "../../api/user";
import { AppContext } from "../../App";
import Button from "../../components/button/Button";
import SubmitButton from "../../components/button/SubmitButton";
import { useObservable } from "../../state/hooks/useObservable/useObservable";
import { useLocizeString } from "../../state/hooks/utils";

import styles from "./styles.module.scss";

const locizeNamespace = "UserInfo";

type UserInfoKey = keyof UserInfo;

type FormKeys = {
    [Property in UserInfoKey]: Property
}

const formKeys: FormKeys = {
    name: "name",
    sex: "sex",
    year_of_birth: "year_of_birth",
    birthplace: "birthplace",
    place: "place",
    work: "work",
    allow_newsletter: "allow_newsletter",
    contact_email: "contact_email"
};

const emptyState = Object.values(formKeys).reduce((prevVal, val) => (
    {...prevVal, [val]: val === formKeys.allow_newsletter ? false : ""}
),{} as UserInfo);

const UserInfoPage = () => {
    const appContext = useContext(AppContext);
    const userInfo = useObservable(appContext.userInfo$);

    const [formInfo, setFormInfo] = useState<UserInfo>(emptyState);
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        if(userInfo){
            setFormInfo(prevState => ({
                ...prevState,
                ...userInfo
            }));
        }
    },[userInfo])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormInfo(prevState => ({
            ...prevState,
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        }));
        setIsValid(event.target.checkValidity());
    }

    const submitForm = () => {
        postUserInfo(formInfo);
        setIsValid(false);
    }
    
    return(
        <form className={styles.form}>
            <h2>{useLocizeString(locizeNamespace, "header", "Personopplysninger")}</h2>
            <p>{useLocizeString(locizeNamespace, "infoText", "Sjekk at informasjonen stemmer. Informasjonen er kun synlig for deg og administratorer av minne")}</p>
    
            <label className={styles.textLabel}>
                <span>{useLocizeString(locizeNamespace, "name", "Navn")}</span>
                <input type="text" name={formKeys.name} value={formInfo[formKeys.name]} onChange={handleChange} />
            </label>
            <label className={styles.textLabel}>
                <span>{useLocizeString(locizeNamespace, "sex", "Kjønn")}</span>
                <input type="text" name={formKeys.sex} value={formInfo[formKeys.sex]} onChange={handleChange} />
            </label>
            <label className={styles.textLabel}>
                <span>{useLocizeString(locizeNamespace, "year_of_birth", "Fødselsår")}</span>
                <input type="text" name={formKeys.year_of_birth} value={formInfo[formKeys.year_of_birth]!} onChange={handleChange} />
            </label>
            <label className={styles.textLabel}>
                <span>{useLocizeString(locizeNamespace, "birthplace", "Fødselssted")}</span>
                <input type="text" name={formKeys.birthplace} value={formInfo[formKeys.birthplace]!} onChange={handleChange} />
            </label>
            <label className={styles.textLabel}>
                <span>{useLocizeString(locizeNamespace, "place", "Bosted")}</span>
                <input type="text" name={formKeys.place} value={formInfo[formKeys.place]} onChange={handleChange} />
            </label>
            <label className={styles.textLabel}>
                <span>{useLocizeString(locizeNamespace, "work", "Stilligstittel")}</span>
                <input type="text" name={formKeys.work} value={formInfo[formKeys.work]} onChange={handleChange} />
            </label>
            <label className={styles.textLabel}>
                <span>{useLocizeString(locizeNamespace, "email", "Epost")}</span>
                <input type="email" name={formKeys.contact_email} value={formInfo[formKeys.contact_email]} onChange={handleChange} required disabled/>
            </label>
            <label className={styles.checkbox}>
                <input type="checkbox" name={formKeys.allow_newsletter} checked={formInfo[formKeys.allow_newsletter]!} onChange={handleChange} />
                <span>{useLocizeString(locizeNamespace, "accept_newsletter", "Motta nyhetsbrev")}</span>
            </label>
            <Button
                className={styles.submitButton} 
                disabled={!isValid}
                label={useLocizeString(locizeNamespace, "save", "Lagre")}
                materialIcon="save"
                onClick={submitForm}
            />
        </form>
    )
}

export default UserInfoPage;
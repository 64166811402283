import { FormUserInfo } from "../SubmitUserInfo";

export enum UserInfoActionType {
    SET_BIRTHPLACE,
    SET_BIRTYEAR,
    SET_NEWSLETTER,
    SET_USER_INFO
}

export type UserInfoAction =
    | { type: UserInfoActionType.SET_BIRTHPLACE, payload: string|null }
    | { type: UserInfoActionType.SET_BIRTYEAR, payload: string|null }
    | { type: UserInfoActionType.SET_NEWSLETTER, payload: boolean|null }
    | { type: UserInfoActionType.SET_USER_INFO, payload: FormUserInfo }


const userInfoReducer = (state: FormUserInfo, action: UserInfoAction): FormUserInfo => {
    switch(action.type) {
        case UserInfoActionType.SET_BIRTHPLACE:
            state = {...state, birthplace: action.payload};
            break;
        case UserInfoActionType.SET_BIRTYEAR:
            state = {...state, year_of_birth: action.payload};
            break;
        case UserInfoActionType.SET_NEWSLETTER:
            state = {...state, allow_newsletter: action.payload};
            break;
        case UserInfoActionType.SET_USER_INFO:
            state = {...action.payload};
            break;
    }
    return state;
}

export default userInfoReducer;
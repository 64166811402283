import { useLocizeString } from "../../state/hooks/utils";
import { TranslationConfig } from "../../packages/memoryWizard/components/submitMemory/translationConfig";

export enum TranslationKey {
    ABOUT_USER_INFO_1,
    BIRTHPLACE_PLACEHOLDER,
    BIRTHPLACE_QUESTION,
    BIRTHYEAR_PLACEHOLDER,
    BIRTHYEAR_QUESTION,
    NEWSLETTER_NO,
    NEWSLETTER_QUESTION,
    NEWSLETTER_YES,
}

const translationConfigs: {[key in TranslationKey]: TranslationConfig} = {
    [TranslationKey.BIRTHPLACE_PLACEHOLDER]: {
        key: "birthplace_placeholder",
        fallback: "Skriv ditt bosted"
    },
    [TranslationKey.BIRTHPLACE_QUESTION]: {
        key: "birthplace_question",
        fallback: "Hvor bor du?"
    },
    [TranslationKey.BIRTHYEAR_PLACEHOLDER]: {
        key: "year_of_birth_placeholder",
        fallback: "Skriv ditt fødselsår"
    },
    [TranslationKey.BIRTHYEAR_QUESTION]: {
        key: "year_of_birth_question",
        fallback: "Når er du født?"
    },
    [TranslationKey.NEWSLETTER_QUESTION]: {
        key: "newsletter_question",
        fallback: "Vil du motta nyhetsbrev om nye innsamlinger og andre ting fra museene?"
    },
    [TranslationKey.NEWSLETTER_YES]: {
        key: "accept_newsletter_label",
        fallback: "Ja takk"
    },
    [TranslationKey.NEWSLETTER_NO]: {
        key: "reject_newsletter_label",
        fallback: "Nei takk"
    },
    [TranslationKey.ABOUT_USER_INFO_1]: {
        key: "about_user_info_line_1",
        fallback: "Vi kan få mer innsikt fra vår innsamling dersom du forteller litt mer om deg selv"
    },
};

const useTranslatedString = (key: TranslationKey) => {
    const config = translationConfigs[key];
    return useLocizeString("userInfo", config.key, config.fallback);
};

export default useTranslatedString;
import React, { useContext } from "react";
import Button from "../../../../../../components/button/Button";
import Dialogue from "../../../../../../components/dialogue/Dialogue";
import Sequencer from "../../../../../../components/sequencer/Sequencer";
import { useObservable } from "../../../../../../state/hooks/useObservable/useObservable";
import { MemoryContext } from "../../../../memoryWizard";
import { SubmitActionType } from "../../state/reducer";

import styles from "./styles.module.scss";
import useTranslatedString, { TranslationKey } from "./translationConfig";

const PublishDialogue = () => {

    const memoryContext = useContext(MemoryContext);
    const state = memoryContext.submitState!;
    const dispatch = memoryContext.submitDispatch!;

    const questions = [
        useTranslatedString(TranslationKey.LINE_1),
        useTranslatedString(TranslationKey.LINE_2),
    ];
    const yesLabel = useTranslatedString(TranslationKey.YES_LABEL);
    const noLabel = useTranslatedString(TranslationKey.NO_LABEL);

    const publish = useObservable(state.publish$);

    const setPublish = (accepts: boolean|null) => {
        dispatch({type:SubmitActionType.SET_PUBLISH, payload: accepts});
    }

    const Options = () => {

        const newsLetterClicked = (event: React.FormEvent<HTMLButtonElement>) => {
            if(publish === undefined || publish === null){
                setPublish(event.currentTarget.value === "yes");
            } else {
                setPublish(null);
            }
        }

        return (
            <Sequencer elements={[
                <Button 
                    label={yesLabel}
                    onClick={newsLetterClicked}
                    value="yes"
                    className={publish ? styles.accepted : styles.submit }
                    hidden={publish === false}
                    key="yes"
                />,
                <Button 
                    label={noLabel}
                    onClick={newsLetterClicked}
                    value="no"
                    className={publish === false ? styles.accepted : styles.submit }
                    hidden={publish === true}
                    key="no"
                />
            ]} skipAnimation={publish !== undefined}/>
        )
    }

    return (
        <Dialogue questions={questions} answer={React.createElement(Options)} animate={publish === undefined}/>
    )
}

export default PublishDialogue;
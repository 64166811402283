import { Loader } from "@googlemaps/js-api-loader";
import React, { useEffect, useRef } from "react";
import { BehaviorSubject } from "rxjs";
import { useObservable } from "../../../../state/hooks/useObservable/useObservable";
import { ItemAnswer } from "../../memoryService";
import AnswerPart from "./AnswerPart";
import styles from "./styles.module.scss";

const mapsLoader = new Loader({
    apiKey: "AIzaSyDvmK6SWv9gXlhy4yUB1Axkh_0Xu3v_lZ0",
    libraries: ["places"]
}).load();


const LocationAnswer = (props: {answer$: BehaviorSubject<ItemAnswer|undefined>}) => {
    const { answer$ } = props;
    const answer = useObservable(answer$);

    const location = answer?.values?.[0].value;
    const mapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const bounds = new google.maps.LatLngBounds();
        mapsLoader.then(() => {
            if(mapRef.current && location){
                const map = new google.maps.Map(mapRef.current, {
                    disableDefaultUI: true,
                    gestureHandling: "none",
                    zoomControl: false,
                });
                const service = new google.maps.places.PlacesService(map);
                service.findPlaceFromQuery({
                    query: location,
                    fields: ["geometry"]
                },(results) => {
                    if(results){
                        const place = results[0];
                        if (place.geometry?.viewport) {
                            bounds.union(place.geometry.viewport);
                        } else if(place.geometry?.location){
                            bounds.extend(place.geometry.location);
                        }
                    }
                    map.fitBounds(bounds);
                })
            }
        });
    },[mapRef, location]);

    return (
        <div className={styles.answerContainer}>
            <div className={styles.map} ref={mapRef} key={location + "map"}></div>,
            <AnswerPart key={location + "text"} text={location} />
        </div>
    );
}
export default LocationAnswer;
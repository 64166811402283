
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useRef } from "react";
import Button from "../../../../../components/button/Button";
import SubmitButton from "../../../../../components/button/SubmitButton";
import Sequencer from "../../../../../components/sequencer/Sequencer";
import { useLocizeString } from "../../../../../state/hooks/utils";
import { MemoryService } from "../../../memoryService";
import { MemoryContext, MemoryContextType } from "../../../memoryWizard";

import styles from "./styles.module.scss";

const LocationInput = () => {
    const searchRef = useRef<HTMLInputElement>(null);
    const memoryContext = useContext(MemoryContext) as MemoryContextType;
    
    var sessionToken = new google.maps.places.AutocompleteSessionToken();
    const autocompleteService = new google.maps.places.AutocompleteService();

    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]|null>(null);
    const [isValid, setIsValid] = useState<boolean>(false);

    const [focus, setFocus] = useState(false);

    const placeholderText = useLocizeString("memoryForm", "locationSearchPlaceholder", "Adresse eller sted");
    const searchLabel = useLocizeString("memoryForm", "searchButtonLabel", "Søk");


    useEffect(()=> {
        if(focus){
            searchRef.current?.focus();
        }
    }, [focus])


    const send = () => {
        if(searchRef.current && searchRef.current.value !== ""){
            autocompleteService.getPlacePredictions({
                input: searchRef.current.value,
                sessionToken: sessionToken
            }, predictions => {
                if(predictions && predictions.length === 1){
                    memoryContext.service?.addAnswerWithText(predictions[0].description);
                } else {
                    setPredictions(predictions);
                }
            });
            setIsValid(false);
        } else {
            memoryContext.service?.addEmptyAnswer();
        }
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsValid(event.target.value.length > 0);
    }

    const submitHandler = (event: React.FormEvent) => {
        event.preventDefault();
        send();
    }

    const searchBox = (
        <form className={styles.searchBar} onSubmit={submitHandler} key="input">
            <input 
                className={styles.searchBox} 
                ref={searchRef} 
                onInput={handleInput}
                placeholder={placeholderText}
            />
            <label className={cn(styles.searchIcon, "material-icons")}>search</label>
            <Button 
                className={cn(styles.returnIcon, "material-icons")} 
                type="submit" 
                materialIcon="keyboard_return"
                tabIndex={-1}
            />
        </form>
    );

    const elements = [
        <div 
            className={styles.container} 
            key="input"
            onAnimationEnd={()=>{setFocus(true)}}
        >
            {searchBox}
            {predictions && <PredictionDisplay items={predictions} memoryService={memoryContext.service}/>}
        </div>,
        <SubmitButton 
            onClick={send} 
            isValid={isValid}
            label={searchLabel}
            key="submit"
        />
    ];

    return (
        <div className={styles.container}>
            <Sequencer elements={elements} />
        </div>
    );
}

const PredictionDisplay = (props: {items: google.maps.places.AutocompletePrediction[], memoryService: MemoryService}) => {

    const { memoryService, items } = props;
    
    const placeSelected = (item: google.maps.places.AutocompletePrediction) => {
        memoryService.addAnswerWithText(item.description);
    }

    const elements = items.map((item, index) => (
        <Button 
            key={item.place_id} 
            onClick={() => placeSelected(item)} 
            autoFocus={index === 0}
            label={item.description}
            className={styles.locationButton}
        />
    ));

    return (
        <div className={styles.predictionDisplay}>
            <Sequencer elements={elements} />
        </div>
    );
}

export default LocationInput;
import { BehaviorSubject } from "rxjs";
import { useObservable } from "../../../../state/hooks/useObservable/useObservable";
import { ItemAnswer } from "../../memoryService";
import AnswerPart from "./AnswerPart";

import styles from "./styles.module.scss";

const dateFormatOptions: Intl.DateTimeFormatOptions  = { dateStyle: "long" };

const DateAnswer = (props: {answer$: BehaviorSubject<ItemAnswer|undefined>}) => {
    
    const { answer$ } = props;
    const answer = useObservable(answer$)

    const dateString = answer?.values?.reduce((acc, val, index) => (
        acc + (index > 0 
              ? " - " + new Date(val.value).toLocaleDateString(undefined, dateFormatOptions)
              : new Date(val.value).toLocaleDateString(undefined, dateFormatOptions)
    )),"");

    return (
        <div className={styles.answerContainer}>
            <AnswerPart text={dateString}/>
        </div>
    )
}

export default DateAnswer;

import cn from "classnames";
import { useContext, useState } from "react";
import { stripHtml } from "string-strip-html";
import { AppContext } from "../../App";
import Button from "../../components/button/Button";
import DmsImage from "../../components/image/DmsImage";
import { useObservable } from "../../state/hooks/useObservable/useObservable";
import { useLocizeString } from "../../state/hooks/utils";

import styles from "./styles.module.scss";

interface AuthorData {
    active_banner: boolean; 
    author: string; 
    banner_author_statement: string, 
    banner_author_statement_i18n?: {
        [i18n:string]: string;
    }; 
    banner_heading?: string
    banner_heading_i18n?: {
        [i18n:string]: string;
    }; 
    banner_text?: string; 
    banner_text_i18n?: {
        [i18n:string]: string;
    }; 
    created: string;
    description: string
    description_i18n: {
        [i18n:string]: string;
    }; 
    dms_id?: string;
    dms_status: string;
    image_height: string;
    image_ratio: string;
    image_width: string;
    mime_type: string;
    modified: string;
    order_by_number: number; 
    summary?: string;
    summary_i18n?: {
        [i18n:string]: string;
    }; 
    type: string;
    uuid: string;
}

type AboutTopicProps = {
    authorData: AuthorData
}

const AboutTopic = ({authorData}: AboutTopicProps) => {
    const appContext = useContext(AppContext);
    const i18n = useObservable(appContext.i18n$);
    const [showInfo, setShow] = useState<boolean>(false);

    const showMore = useLocizeString("topic", "showMore", "Vis mer");
    const showLess = useLocizeString("topic", "showLess", "Vis mindre");

    const expandLabel = showInfo ? showLess : showMore;

    const cleanString = (htmlString: string | undefined, strict = false) => {
        if(htmlString){
            return stripHtml(htmlString, {ignoreTags: strict ? [] : ["br","div","bold","a", "ul", "li"]}).result
        }
        return "";
    };
    
    return (
        <>
            <div className={cn(styles.aboutFooter, showInfo && styles.aboutFooter_visible)}>
                <section>
                    <div className={styles.aboutCard}>
                        <div className={styles.aboutAuthor}>
                            {authorData.dms_id && 
                                <div className={styles.authorPresentation}>
                                    <div className={styles.imageContainer}>
                                        <DmsImage dmsId={authorData.dms_id} />
                                    </div>
                                    <h3 dangerouslySetInnerHTML={{__html: cleanString(authorData.author, true)}} />
                                    <h4 dangerouslySetInnerHTML={{__html: cleanString(authorData.summary_i18n?.[i18n] || authorData.summary , true)}} />
                                </div>
                            }
                            <div className={styles.authorStatement}>
                                <p dangerouslySetInnerHTML={
                                    {__html: cleanString(authorData.banner_author_statement_i18n?.[i18n] || authorData.banner_author_statement)}
                                } />
                            </div>
                        </div>
                        <div className={styles.aboutBanner}>
                            <h2>{authorData.banner_heading_i18n?.[i18n] || authorData.banner_heading}</h2>
                            <p dangerouslySetInnerHTML={
                                {__html: cleanString(authorData.banner_text_i18n?.[i18n] || authorData.banner_text)}
                            } />
                        </div>
                    </div>
                </section>
            </div>
            <Button
                className={styles.showMoreButton} 
                onClick={() => setShow(!showInfo)}
                materialIcon={showInfo ? "expand_less" : "expand_more"}
                label={expandLabel} 
            />
        </>
    );
}

export default AboutTopic;
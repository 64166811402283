import { BehaviorSubject } from "rxjs";
import { MemoryResponse } from "../../../../../api/response";
import { FormUserInfo } from "../../../../../components/userInfo/SubmitUserInfo";

export enum SubmitActionType {
    INIT_FROM_RESPONSE,
    SET_CONTRIBUTOR,
    SET_EMAIL,
    SET_PSAUDO,
    SET_PUBLISH,
    SET_TERMS_ACCEPTED,
    SET_USER_INFO,
    CLEAR,
    TOGGLE_USE_CONTRIBUTOR,
    TOGGLE_USE_NAME,
    TOGGLE_USE_PSAUDO,
    USE_PSAUDO,
}

export type SubmitAction =
    | { type: SubmitActionType.SET_TERMS_ACCEPTED }
    | { type: SubmitActionType.TOGGLE_USE_NAME }
    | { type: SubmitActionType.TOGGLE_USE_PSAUDO }
    | { type: SubmitActionType.TOGGLE_USE_CONTRIBUTOR }
    | { type: SubmitActionType.USE_PSAUDO }
    | { type: SubmitActionType.SET_EMAIL, payload: string }
    | { type: SubmitActionType.SET_PSAUDO, payload: string }
    | { type: SubmitActionType.SET_PUBLISH, payload: boolean|null|undefined }
    | { type: SubmitActionType.SET_CONTRIBUTOR, payload: string }
    | { 
        type: SubmitActionType.SET_USER_INFO, 
        payload: FormUserInfo
    }
    | { 
        type: SubmitActionType.INIT_FROM_RESPONSE, 
        payload: MemoryResponse
    }
    | { type: SubmitActionType.CLEAR }

export type SubmitState = {
    authorIsSet$: BehaviorSubject<boolean|null>;
    collectorSelected$: BehaviorSubject<boolean>;
    contributor$: BehaviorSubject<string>,
    email$: BehaviorSubject<string|undefined>
    nameSelected$: BehaviorSubject<boolean>;
    psaudoSelected$: BehaviorSubject<boolean>;
    psaudonym$: BehaviorSubject<string>,
    publish$: BehaviorSubject<boolean|null|undefined>,
    termsAccepted$: BehaviorSubject<boolean>;
    userInfo$: BehaviorSubject<FormUserInfo|undefined>;
}

export const initialState = () : SubmitState => (
    {
        authorIsSet$: new BehaviorSubject<boolean|null>(false),
        collectorSelected$: new BehaviorSubject<boolean>(false),
        contributor$: new BehaviorSubject(""),
        email$: new BehaviorSubject<string|undefined>(undefined),
        nameSelected$: new BehaviorSubject<boolean>(false),
        psaudoSelected$: new BehaviorSubject<boolean>(false),
        psaudonym$: new BehaviorSubject(""),
        publish$: new BehaviorSubject<boolean|null|undefined>(undefined),
        termsAccepted$: new BehaviorSubject<boolean>(false),
        userInfo$: new BehaviorSubject<FormUserInfo|undefined>(undefined),
    }
);

const submitReducer = (state: SubmitState, action: SubmitAction): SubmitState => {
    switch(action.type) {
        case SubmitActionType.SET_TERMS_ACCEPTED:
            state.termsAccepted$.next(true);
            break;
        case SubmitActionType.SET_USER_INFO:
            state.userInfo$.next(action.payload);
            break;
        case SubmitActionType.TOGGLE_USE_NAME:
            const useName = !state.nameSelected$.value;
            state.nameSelected$.next(useName);
            state.authorIsSet$.next(useName ? true : null);
            break;
        case SubmitActionType.TOGGLE_USE_PSAUDO:
            const usePsaudo = !state.psaudoSelected$.value;
            state.psaudoSelected$.next(usePsaudo);
            !usePsaudo && state.authorIsSet$.next(null);
            break;
        case SubmitActionType.USE_PSAUDO:
            state.psaudoSelected$.next(true);
            break;
        case SubmitActionType.TOGGLE_USE_CONTRIBUTOR:
            const useContributor = !state.collectorSelected$.value;
            state.collectorSelected$.next(useContributor);
            !useContributor && state.authorIsSet$.next(null);
            break;
        case SubmitActionType.SET_CONTRIBUTOR:
            state.contributor$.next(action.payload);
            state.authorIsSet$.next(true);
            break;
        case SubmitActionType.SET_PSAUDO: 
            state.psaudonym$.next(action.payload);
            state.authorIsSet$.next(true);
            break;
        case SubmitActionType.SET_PUBLISH: 
            state.publish$.next(action.payload);
            break;
        case SubmitActionType.SET_EMAIL: 
            state.email$.next(action.payload);
            break;
        case SubmitActionType.CLEAR:
            state.authorIsSet$.next(false);
            state.collectorSelected$.next(false);
            state.contributor$.next("");
            state.email$.next(undefined);
            state.nameSelected$.next(false);
            state.psaudoSelected$.next(false);
            state.psaudonym$.next("");
            state.publish$.next(undefined);
            state.termsAccepted$.next(false);
            state.userInfo$.next(undefined);
            break;
        case SubmitActionType.INIT_FROM_RESPONSE:
            const response = action.payload;
            
            state.termsAccepted$.next(true);
            state.publish$.next(response.published);
            state.authorIsSet$.next(true);

            if(response.contributor?.uuid){
                state.collectorSelected$.next(true);
                state.contributor$.next(response.contributor.uuid);
            } else if (response.author_alias){
                state.psaudoSelected$.next(true);
            } else {
                state.nameSelected$.next(true);
            }
    }
    return state;
}

export default submitReducer;
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router"
import { BehaviorSubject } from "rxjs";
import { getPreviewUrl } from "../../api/upload";
import { SiteLng } from "../../App";
import { MediaStatus } from "../../components/mediaStatus/mediaStatus";
import { useObservable } from "./useObservable/useObservable";

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const usePrevious = <T>(value: T) => {
    const ref = useRef(value);

    useEffect(()=>{
        ref.current = value;
    },[value])

    return ref.current;
}

const useLocize = (nameSpace: string) => {
    const {t, ready} = useTranslation(`innsamlingsapp.${nameSpace}`, {
        useSuspense: false
    });

    return {t, ready};
}

export const useLocizeString = (nameSpace: string, key: string, fallback: string) => {
    const {t, ready} = useLocize(nameSpace);
    const [text, setText] = useState<string>("");

    useEffect(()=>{
        if(ready){
            const translatedText = t(key, fallback);
            setText(translatedText);
        }
    },[t, key, fallback, ready])

    return text;
}

export const useCurrentMemoryLocale = (i18n$: BehaviorSubject<SiteLng>) => {
    const i18n = useObservable(i18n$);
    const [locale, setLocale] = useState<string>("en_gb");

    useEffect(() => {
        switch(i18n){
            case SiteLng.en:
                setLocale("en_gb");
                break;
            case SiteLng.no:
                setLocale("nb_no");
                break
            case SiteLng.sv:
                setLocale("sv_se");
                break;
        }
    },[i18n])

    return locale;
}
import React, { useRef, useState, useEffect, useContext, useCallback } from "react";

import Button from "../../../../../components/button/Button";
import SubmitButton from "../../../../../components/button/SubmitButton";
import Sequencer from "../../../../../components/sequencer/Sequencer";
import { useObservable } from "../../../../../state/hooks/useObservable/useObservable";
import { MemoryContext, MemoryContextType } from "../../../memoryWizard";
import MediaRecorder from "../../mediaRecorder/mediaRecorder";
import { TextInputService, MediaEntry } from "./TextInputService";

import styles from "./styles.module.scss";
import { useLocizeString } from "../../../../../state/hooks/utils";
import MediaDisplay from "../../../../../components/mediaDisplay/MediaDisplay";

type MediaPreviewProps = {
    item: MediaEntry, 
    inputService: TextInputService, 
    partIndex: number,
    edit?: boolean
}

const Preview = (props: MediaPreviewProps) => {

    const {item, inputService, partIndex, edit} = props;

    const deleteItem = () => {
        inputService.removeMediaFromPart(partIndex);
    }

    return (
        <div className={styles.previewContainer}>
            <div className={styles.preview}>
                <MediaDisplay item={item} showControls={edit} />
            </div>
            {edit && <Button className={styles.deleteMedia} materialIcon="delete_outline" onClick={deleteItem}/>}
        </div>
    );
}

type InputFormProps = {
    showMedia?: boolean;
    inputService: TextInputService,
    focus: boolean,
    partIndex: number
}

const InputForm = (props: InputFormProps) => {

    const { showMedia, inputService, focus, partIndex } = props;

    const recorderEnabled = useObservable(inputService.recorderEnabled$);

    const media = inputService.parts$.value?.[partIndex]?.media;
    const text = inputService.parts$.value?.[partIndex]?.text;

    const fileInputRef = useRef<HTMLInputElement>(null);

    const inputPlaceholder = useLocizeString("memoryForm", "textInputPlaceholder", "Skriv et svar");
    const mediaDescriptionPlaceholder = useLocizeString("memoryForm", "mediaDescriptionPlaceholder", "Legg til beskrivelse")

    const handleKeyPressForUpload = (e: React.KeyboardEvent) => {
        if(e.key === "Enter"){
            fileInputRef.current?.click();
        }
    }

    useEffect(()=>{
        if(focus){
            textAreaRef.current?.focus();
        }
    },[focus]);

    const resizeInput = useCallback(() => {
        const target = textAreaRef.current;

        if(target){
            target.style.removeProperty("height");
            target.style.height = target.scrollHeight - 10 + "px";
            if(target.textLength > 0){
                inputService.setHasText(true);
            } else {
                inputService.setHasText(false);
            }
        }
    },[inputService]);

    useEffect(()=> {
        resizeInput();
    },[text, resizeInput]);

    const fileUploaded = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(media){
            submitText(false);
        }

        if(event.target.files?.length){
            const lastImage = event.target.files[event.target.files.length - 1];
            inputService.addMediaToEditedPart(lastImage.type, URL.createObjectURL(lastImage));
        }
    }

    const startRecord = () => {
        if(media){
            submitText(false);
        }
        inputService.toggleRecorder()
    }

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        resizeInput();
    }

    const submitText = (submitClicked?: boolean) => {
        if(textAreaRef.current){
            inputService.addTextToPart(partIndex, textAreaRef.current.value, submitClicked);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if(event.key === "Enter"){
            event.preventDefault();
            submitText();

        } else if(event.key === "Backspace") {
            // Todo add double backspae handler
        }
    }

    const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submitText(true);
    };

    return(
        <form id="inputForm" onSubmit={submitHandler}>
                { media && <Preview item={media} inputService={inputService} partIndex={partIndex} edit/>}
                { recorderEnabled && <MediaRecorder inputService={inputService} partIndex={partIndex}/> }  
                <div className={styles.inputBar} style={{display: recorderEnabled ? "none" : "flex"}}>         
                    <div style={{display: showMedia ? 'flex' : 'none'}}>
                        <label className={styles.upload} role="button" tabIndex={0} onKeyPress={handleKeyPressForUpload}>
                            <span className="material-icons">image</span>
                            <input type="file" accept="image/*,video/*,audio/*" onChange={fileUploaded} ref={fileInputRef}></input>
                        </label>
                        <Button 
                            materialIcon="photo_camera" 
                            onClick={startRecord}
                            className={styles.camera}
                        />  
                    </div>
                    <textarea 
                        ref={textAreaRef} 
                        className={styles.textInput} 
                        onInput={handleInput}
                        onKeyPress={handleKeyPress}
                        placeholder={media ? mediaDescriptionPlaceholder : inputPlaceholder}
                        rows={1}
                        defaultValue={text} 
                    />
                    <Button 
                        materialIcon="keyboard_return"
                        onClick={() => submitText(false)} 
                    />
                </div>   
        </form>
    )
}

const InputDisplay = (props: {showMedia?: boolean, inputService: TextInputService}) => {

    const { inputService } = props;
    const hasInput = useObservable(inputService.hasInput$);
    const parts = useObservable(inputService.parts$);
    const editPartIndex = useObservable(inputService.editPartIndex$);

    const [focus,setFocus] = useState(false);

    const handleAnimationEnd = () => {
        setFocus(true);
    }

    const elements = [
        <div 
            className={styles.input} 
            key="input"
            onAnimationEnd={handleAnimationEnd}
        >
            {
                parts?.map((val, index) => (                 
                    index === editPartIndex 
                        ? <InputForm key={index} focus={focus} partIndex={index} {...props} />
                        : <div 
                            key={index + "preview"} 
                            className={styles.inputPart}
                            onClick= {() => {
                                inputService.editPart(index);
                            }}
                        >
                            { val.media && <Preview item={val.media} inputService={inputService} partIndex={index}/> }
                            <p>{val.text}</p>
                            <Button materialIcon="edit"></Button>
                        </div>                   
                ))
            }
        </div>,
        <SubmitButton
            form="inputForm"
            isValid={hasInput}
            key="submit"
        /> 
    ];

    return (
        <Sequencer elements={elements} />  
    );
}

const TextInput = (props: {showMedia?: boolean}) => {

    const memoryContext = useContext(MemoryContext) as MemoryContextType;
    const inputService = new TextInputService(memoryContext);

    return (
        <InputDisplay inputService={inputService} {...props}/>
    )

}

export default TextInput;


import {refreshToken, login, logout} from "@ekultur/authentication"
import { BehaviorSubject } from "rxjs"

export const isLoggedIn = () => {
    const isLoggedIn = new BehaviorSubject(false);
    refreshToken({
        apiGateway: window._env_.REACT_APP_API_GATEWAY,
        onSuccess: () => isLoggedIn.next(true),
        onError: () => isLoggedIn.next(false)
    });
    return isLoggedIn;    
}

export const loginAndRedirect = (toUrl: string) => {
    login({
        locationPostLogin: toUrl,
        redirectUri: null,
        loginHost: window._env_.REACT_APP_LOGIN_HOST
    });
}

export const deferToken = () => {
    logout({
        signal: null,
        onLogout: () => {},
        apiGateway: window._env_.REACT_APP_API_GATEWAY,
    })   
}
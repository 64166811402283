import { useParams } from "react-router";
import MemoizedMemoryWizard from "../../memoryWizard/memoryWizard";
import {getResponse} from "../../../api/response"
import { useApiWithArgs } from "../../../state/hooks/useApi/useApi";
import { Header } from "../../../header/Header";

import styles from "./styles.module.scss"
import { useLocizeString } from "../../../state/hooks/utils";
import { useEffect, useRef, useState } from "react";

type EditInfo = {
    topicId: string|null,
    isV2: boolean
}

const useResponseEditInfo = (uuid: string) => {
    const [info, setInfo] = useState<EditInfo>({topicId: null, isV2: false});
    const infoRef = useRef<EditInfo>();
    
    const response = useApiWithArgs(getResponse,[uuid]);

    useEffect(() => {
        if(response && infoRef.current?.topicId !== response?.topic.uuid){
            const info = {
                topicId: response.topic.uuid,
                isV2: !!response.v2
            };
            infoRef.current = info;
            setInfo(info);
        }
    },[response])

    return info;
}

const EditResponse = () => {
    const { uuid } = useParams<{uuid: string}>();

    const {topicId, isV2} = useResponseEditInfo(uuid);

    const errorText = useLocizeString("edit_response", "old_response_error", "Kan ikke editere minner innsendt fra gammel innsamling");

    return (
        <>
            <div className={styles.top}></div>
            <section>
                <div className={styles.conversation}>
                    {topicId && (
                        isV2
                            ? <MemoizedMemoryWizard topic_id={topicId} uuid={uuid}/>
                            : {errorText}
                    )}
                </div>
            </section>
            <Header />
        </>
    )
}

export default EditResponse;
import { useContext, useState } from "react";
import cn from "classnames";

import Button from "../../../../../../components/button/Button";
import Dialogue from "../../../../../../components/dialogue/Dialogue";
import Sequencer from "../../../../../../components/sequencer/Sequencer";

import { SubmitActionType } from "../../state/reducer";
import { useObservable } from "../../../../../../state/hooks/useObservable/useObservable";
import { MemoryContext } from "../../../../memoryWizard";

import useTranslatedString, { TranslationKey } from "./translationConfig";

import styles from "./styles.module.scss";


export type TermsProps = {
    terms: string
};

const Terms = ({terms}: TermsProps) => {
    const memoryContext = useContext(MemoryContext);
    const state = memoryContext.submitState!;
    const dispatch = memoryContext.submitDispatch!;
    
    const [showTerms, setShowTerms] = useState<boolean>(false);
    const termsAccepted = useObservable(state.termsAccepted$);

    const header = useTranslatedString(TranslationKey.TERMS_HEADER);
    const acceptLabel = useTranslatedString(TranslationKey.ACCEPT_AND_CLOSE);
    
    const TermsModal = () => (
        <div className={styles.termsBackground}>
            <div className={styles.terms}>
                <div className={styles.termsHeader}>
                    <div className={styles.headerLabel}>
                        <span className={"material-icons"}>gavel</span>
                        <h2>{header}</h2>
                    </div>
                    <Button 
                        materialIcon="close"
                        onClick={()=>setShowTerms(false)}
                    />
                </div>
                <div className={styles.termsContent}>
                    <div dangerouslySetInnerHTML={{__html: terms!}}></div>
                    <Button 
                        className={styles.submit}
                        label={acceptLabel}
                        onClick={()=>{
                            setShowTerms(false)
                            dispatch({type: SubmitActionType.SET_TERMS_ACCEPTED})
                        }}
                    />
                </div> 
            </div>
        </div>
    );

    const termsDialogue = [
        useTranslatedString(TranslationKey.TERMS_INFO)
    ];
    const showTermsLabel = useTranslatedString(TranslationKey.SHOW_TERMS);
    const acceptTermsLabel = useTranslatedString(TranslationKey.ACCEPT_TERMS);

    const termAlternatives = [
        <Button 
            className={styles.submit}
            label={showTermsLabel}
            hidden={termsAccepted}
            onClick={()=>setShowTerms(true)}
            key="showTerms"
        />,
        <Button 
            className={cn(styles.submit, termsAccepted && styles.accepted)}
            label={acceptTermsLabel}
            onClick={() => dispatch({type: SubmitActionType.SET_TERMS_ACCEPTED})}
            key="acceptTerms"
        />
    ];

    const termsSequence = (
        <Sequencer elements={termAlternatives} skipAnimation={termsAccepted !== undefined}/>
    );

    return (
        <>
            { showTerms && <TermsModal /> }
            <Dialogue questions={termsDialogue} answer={termsSequence} animate={termsAccepted === undefined}/>
        </> 
    );
};

export default Terms;
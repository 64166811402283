import React from "react";
import { useState } from "react";

import Button from "../button/Button";
import SubmitButton from "../button/SubmitButton";
import Dialogue from "../dialogue/Dialogue";
import Sequencer from "../sequencer/Sequencer";
import { useLocizeString } from "../../state/hooks/utils";

import styles from "./styles.module.scss";
import useTranslatedString, { TranslationKey } from "./translationConfig";
import { UserInfoAction, UserInfoActionType } from "./state/reducer";

type BirthPlaceProps = {
    location: string|null|undefined;
    dispatch: React.Dispatch<UserInfoAction>,
} 

const BirthPlaceDialogue = ({location, dispatch}: BirthPlaceProps) => {
    
    const questions = [
        useTranslatedString(TranslationKey.BIRTHPLACE_QUESTION)
    ];
    const skipLabel = useLocizeString("memoryForm", "skip", "Hopp over");
    const placeholder = useTranslatedString(TranslationKey.BIRTHPLACE_PLACEHOLDER);
    
    const [edit, setEdit] = useState(false);

    const setLocation = (location: string|null) => {
        dispatch({type: UserInfoActionType.SET_BIRTHPLACE, payload: location});
    }

    const answer = (
        <Button 
            label={location || skipLabel}
            onClick={() => setEdit(true)}
            className={styles.accepted}
        />
    );

    const Options = () => {
        const [input, setInput] = useState(location || "");

        const handleSubmit = (event: React.FormEvent) => {
            event.preventDefault();
            setLocation(input.length > 0 ? input : null);
            setEdit(false);
        }

        const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
            setInput(event.currentTarget.value);
        }

        return (
            <form onSubmit={handleSubmit}>
                <Sequencer elements={[
                    <div className={styles.inputBox} key="input">
                        <div className={styles.inputRow}>
                            <input
                                type="text"
                                value={input}
                                placeholder={placeholder}
                                onChange={handleInput}
                            />
                            <Button 
                                materialIcon="keyboard_return"
                                type="submit"
                            />
                        </div>
                    </div>,
                    <SubmitButton 
                        isValid={!!input && input.length > 1}
                        key="submit"
                    />
                ]} skipAnimation={location !== undefined} />
            </form>
        )
    }

    const answerOrOptions = (
        location !== undefined && !edit ? answer : <Options />
    );

    return (
        <Dialogue questions={questions} answer={answerOrOptions} animate={location === undefined} />
    )
}

export default BirthPlaceDialogue;
import React, { useContext, useState } from "react";
import { AppContext } from "../../../../../../App";
import Button from "../../../../../../components/button/Button";
import SubmitButton from "../../../../../../components/button/SubmitButton";
import Dialogue from "../../../../../../components/dialogue/Dialogue";
import Sequencer from "../../../../../../components/sequencer/Sequencer";
import { useObservable } from "../../../../../../state/hooks/useObservable/useObservable";
import { MemoryContext } from "../../../../memoryWizard";
import { SubmitActionType } from "../../state/reducer";

import styles from "./styles.module.scss";
import useAuthorString, { TranslationKey } from "./translationConfig";

const PsaudoInput = () => {
    const memoryContext = useContext(MemoryContext);
    const appContext = useContext(AppContext);
    const isLoggedIn = useObservable(appContext.isLoggedIn$);

    const state = memoryContext.submitState!;
    const dispatch = memoryContext.submitDispatch!;
    
    const loggedInInfo = [
        useAuthorString(TranslationKey.PSAUDO_PROMT)
    ];

    const emailNameInfo = [
        useAuthorString(TranslationKey.EMAIL_INFO_NAME),
        useAuthorString(TranslationKey.EMAIL_ABOUT_NAME)
    ];

    const questions = isLoggedIn ? loggedInInfo : emailNameInfo;
   
    const authorIsSet = useObservable(state.authorIsSet$);
    const psaudonym = useObservable(state.psaudonym$);

    const [edit, setEdit] = useState(false);

    const answer = (
        <Button 
            label={psaudonym}
            onClick={() => setEdit(true)}
            className={styles.accepted}
        />
    );

    const Options = () => {
        const [input, setInput] = useState<string>(psaudonym);
        const psaudoPlaceholder = useAuthorString(TranslationKey.PSAUDO_PLACEHOLDER);
        const emailNamePlaceholder = useAuthorString(TranslationKey.EMAIL_NAME_PLACEHOLDER);

        const handleSubmit = (event: React.FormEvent) => {
            event.preventDefault();
            if(input){
                dispatch({type: SubmitActionType.SET_PSAUDO, payload: input});
                setEdit(false);
            }
        }
    
        const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
            setInput(event.currentTarget.value);
        }

        return (
            <form onSubmit={handleSubmit}>
                <Sequencer elements={[
                    <div className={styles.inputBox} key="input">
                        <div className={styles.inputRow}>
                            <input
                                type="text"
                                placeholder={isLoggedIn ? psaudoPlaceholder : emailNamePlaceholder} 
                                onChange={handleInput}
                                value={input}
                            />
                            <Button 
                                materialIcon="keyboard_return"
                                type="submit"
                            />
                        </div>
                    </div>,
                    <SubmitButton 
                        isValid={input.length > 1}
                        key="submit"
                    />
                ]} skipAnimation={!!authorIsSet} />
            </form>
        )
    };

    const answerOrOptions = (
        psaudonym && !edit ? answer : <Options />
    );

    return (
        <Dialogue questions={questions} answer={answerOrOptions} animate={!authorIsSet}/>
    )
};

export default PsaudoInput;
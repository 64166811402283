import cn from "classnames";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { AppContext } from "../App";
import Button from "../components/button/Button";
import { useObservable } from "../state/hooks/useObservable/useObservable";

import styles from './styles.module.scss';
import { useLocation } from "react-router";
import { deferToken, loginAndRedirect } from "../packages/oauth2/utils";
import { useLocizeString } from "../state/hooks/utils";

import i18n from "../i18n";
import { Link } from "react-router-dom";

type LocizeLanguages = {
    [lng: string]: {
        name: string,
        nativeName: string,
        isReferenceLanguage: boolean,
        translate: {[version:string]: number}
    }
}

const Menu = () => {
    const context = useContext(AppContext);
    const isLoggedIn = useObservable(context.isLoggedIn$);
    const show = useObservable(context.menuVisible$);

    const menuRef = useRef<HTMLDivElement>();

    const setShow = useCallback((show: boolean) => {
        context.menuVisible$.next(show);
    },[context]);

    useEffect(()=>{
        const handleClickOutside = (event: Event) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };        
    },[menuRef, setShow])

    
    const currentLng = useObservable(context.i18n$);
    const currentUrl = useLocation().pathname;

    const loginLabel = useLocizeString("menu", "login", "Logg inn");
    const logoutLabel = useLocizeString("menu", "logout", "Logg ut");
    const listLabel = useLocizeString("menu", "about", "Om minner");

    const [languages, setLanguages] = useState<LocizeLanguages>(); 

    useEffect(() => {
        i18n.services.backendConnector.backend.getLanguages((error: any, languages: LocizeLanguages) => {
            if(!error){
                setLanguages(languages);
            }
        });
    }, []);

    const version = "v" + window._env_.REACT_APP_VERSION;
    
    const lngChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.currentTarget.value);
    }

    const loginClicked = () => {
        loginAndRedirect(currentUrl);
    }
    const logoutClicked = () => {
        deferToken();
        context.isLoggedIn$.next(false);
        setShow(false);
    }

    const LoginOrMyPage = () => {
        return isLoggedIn 
            ? (
                <>
                    <Link to="/myPage" onClick={() => setShow(false)}>Min side</Link>
                    <Button label={logoutLabel} onClick={logoutClicked} className={styles.logoutButton}/></>
            )
            : (
                <Button onClick={loginClicked} label={loginLabel} />
            )
    };


    return (
        <div className={cn(styles.menu, show && styles.menu_visible)} ref={menuRef}>
            <Button materialIcon="close" className={styles.close} onClick={() => setShow(false)}/>
            <ul>
                <li><LoginOrMyPage /></li>
                <li><Link to="/" onClick={() => setShow(false)}>{listLabel}</Link></li>
                <li>
                    <select value={currentLng} onInput={lngChanged}>
                        {   languages &&
                                Object.keys(languages).map(lng => (
                                    <option value={lng} key={lng}>{languages[lng].nativeName}</option> 
                                ))
                        }
                    </select>
                </li>
            </ul>
            <span className={styles.version}>{version}</span>
        </div>
    );
}

export default Menu;
import React, { useContext } from "react"
import Button from "../../../../../components/button/Button";
import { MediaRecorderContext } from "../mediaRecorder"
import styles from "./styles.module.scss";

const CameraToggleButton = () => {
    const context = useContext(MediaRecorderContext);

    return (
        <Button 
            materialIcon="flip_camera_android"
            className={styles.cameraToggle} 
            onClick={() => context.controller?.toggleInputSource()} 
        />
    )
}

export default CameraToggleButton;
import { Link, NavLink } from "react-router-dom";
import { useLocizeString } from "../../state/hooks/utils";

import styles from "./styles.module.scss";

const locizeNamespace = "myPageMenu";

const PageMenu = () => {
    return(
        <menu className={styles.pageMenu}> 
                <li>
                    <NavLink exact to="/myPage" activeClassName={styles.active}>{
                        useLocizeString(locizeNamespace, "memories", "Mine minner")}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/myPage/userInfo" activeClassName={styles.active}>
                        {useLocizeString(locizeNamespace, "userInfo", "Personopplysninger")}
                    </NavLink>
                </li>
        </menu>
    )
}

export default PageMenu;
import cn from "classnames";
import styles from "./styles.module.scss";

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
    label?: string,
    materialIcon?: string,
    type?: "button" | "submit" | "reset",
};

const Button = (props: ButtonProps) => {

    const {label, materialIcon, className, type, ...rest} = props;

    const buttonClass = cn(styles.button, className);

    return (
        <button className={buttonClass} type={type ? type : "button"} {...rest}>
            {materialIcon && (<span className={cn("material-icons", styles.icon, label && styles.withLabel)}>{materialIcon}</span>)}
            {label && (<label>{label}</label>)}
        </button>
    );
}

export default Button;
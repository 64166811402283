import { useContext, useEffect } from "react";
import { useObservable } from "../../../../../state/hooks/useObservable/useObservable";
import { MediaRecorderContext } from "../mediaRecorder";
import { RecordingState, MediaType } from "../mediaRecorderService";
import RecordControls from "../recordControls/RecordControls";
import useAudioOnCanvas from "../../../../../state/hooks/useCanvas/useCanvas";
import Button from "../../../../../components/button/Button";

import styles from "./styles.module.scss";

const AudioDisplay = () => {
    const context = useContext(MediaRecorderContext);
    const mediaSource = useObservable(context.recorder?.mediaSource$);
    const recordingState = useObservable(context.recorder?.recorderState$);

    const {containerRef, canvasRef, setData} = useAudioOnCanvas();

    useEffect(() => {
        if(mediaSource?.isObject){
            const audioContext = new AudioContext();
            const source = audioContext.createMediaStreamSource(mediaSource.src);
            const processor = audioContext.createScriptProcessor(2048, 2, 1);

            if(recordingState === RecordingState.RECORDING){
                source.connect(processor);
                processor.connect(audioContext.destination);
    
                processor.onaudioprocess = function(ev){
                    if(canvasRef.current){
                        const data = ev.inputBuffer.getChannelData(0);
                        const avg = data.reduce(({avg, n}, currentValue) => ({
                            avg: (Math.abs(currentValue) + n * avg) / (n + 1),
                            n: n+1
                        }), {avg: 0, n:0}).avg;
                        setData(avg);
                    }
                }
            }
        }
    },[mediaSource, recordingState, setData]);

    return (
        <div ref={containerRef}>
            <canvas ref={canvasRef} />
            <Button className={styles.mic} materialIcon="mic_none" disabled/>
        </div>
    );
}

const AudioRecorder = () => {
    const context = useContext(MediaRecorderContext);
    const state = useObservable(context.recorder?.recorderState$);

    useEffect(() => {
        context.recorder?.init(MediaType.audio); 
 
        return(() => {
            context.recorder?.clear();
        })
     },[context])

     useEffect(() => {
        if(state === RecordingState.STOPPED){
            context.inputService?.addMediaToEditedPart(MediaType.audio, context.recorder!.lastRecording);
        }
     },[state, context])

    return (
        <div>
            <AudioDisplay />
            <RecordControls />
        </div>
    )
}

export default AudioRecorder;


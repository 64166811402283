import React, { useContext, useEffect, useState } from 'react';

import Button from '../components/button/Button';

import logo from '../assets/images/nf_logo.png'
import cn from 'classnames';
import { BehaviorSubject } from 'rxjs';
import { useObservable } from '../state/hooks/useObservable/useObservable';
import Menu from './Menu';

import styles from './styles.module.scss';
import { AppContext } from '../App';
import { useLocizeString } from '../state/hooks/utils';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export const headerResizer = {
    isSmall$: new BehaviorSubject(false),
}

export const UserInitials = () => {
    const appContext = useContext(AppContext);
    const initials = useObservable(appContext.userInfo$)?.name.split(" ").reduce((prevVal,val) => {
        return prevVal + val.charAt(0).toUpperCase();
    }, "");

    return (
        <div className={styles.initials}><span>{initials}</span></div>
    )
}

interface Owner {
    created: string; 
    email: string; 
    feedback_email: string; 
    identifier: string; 
    logo: string; 
    modified: string; 
    name: string; 
    type: {
      created: string; 
      id: number; 
      modified: string; 
      name: string;
    }; 
    uuid: string; 
    web: string;
}

export const Header = ({owner} : {owner?: Owner}) => {
    const appContext = useContext(AppContext);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
    const [isScrolled, setIsScrolled] = useState(false);
    
    const showMenu = useObservable(appContext.menuVisible$);
    const setShowMenu = (show: boolean) => {
        appContext.menuVisible$.next(show);
    } 

    const isLoggedIn = useObservable(useContext(AppContext).isLoggedIn$);
    
    const isSwedishSite = window.location.hostname.endsWith(".se");
    
    const isBeta = window._env_.REACT_APP_IS_BETA;
    const title = `${isBeta === "true" ? "beta-" : ""}${isSwedishSite ? "minnen" : "minner"}`;
    const menuLabel = useLocizeString("header", "menuLabel", "Meny");

    useEffect(() => {
        if (typeof window !== "undefined") {
            const onResize = () => setIsMobile(window.innerWidth <= 640);
            window.addEventListener('resize', onResize);

            const root = document.querySelector("#root");

            const onScroll = () => {
                const isScrolled = document.documentElement.scrollTop > 20;
                setIsScrolled(isScrolled);
            };

            window.addEventListener("scroll", onScroll);
            onScroll();

            return () => {
                window.removeEventListener('resize', onResize);
                window.removeEventListener('scroll', onScroll);
            }
        }
    }, []);

    const Logo = () => {
        if(owner){
            return (<a href={owner.web} className={cn(styles.logo,styles[owner.identifier])} aria-label={`${owner.name} logo`}></a>)
        } else {
            return (<h1 className={styles.name}><Link to="/">{title}</Link></h1>)
        }
    }

    return (
        <header className={cn(styles.header, isScrolled && styles.minimized)}>
            <nav className={styles.nav}>
                <div className={styles.left}>
                    <Logo />
                </div>
                <div className={styles.right}>
                    { isLoggedIn && <UserInitials />}
                    <Button
                        label={isMobile ? undefined : menuLabel}
                        materialIcon="menu"
                        className={styles.menuButton}
                        onClick={() => {setShowMenu(!showMenu)}}
                    />
                </div>
            </nav>
            <Menu/>
        </header>
    );
};

import { useCallback, useEffect, useRef, useState } from "react";

const barWidth = 2;
const barGap = 2;

const useAudioOnCanvas = () => {
    const {containerRef, canvasRef, ctx} = useCanvas();

    const [data, setData] = useState<number|Float32Array|undefined>();
    const [audioArray, setAudioArray] = useState<number[]>([]);

    const addBar = useCallback((value: number, index: number) => {
        const position = (index * barWidth) + (index * barGap);
        let panning = false;

        if(ctx){
            const draw = (position: number, avg: number) => {
                const canvasHeight = canvasRef.current!.height;
                const amp = Math.max(5, avg*canvasHeight*3);
                ctx.lineWidth = barWidth;
                ctx.strokeStyle = "#000";
                ctx.imageSmoothingEnabled = false;
                ctx.beginPath();
                ctx.moveTo(position, canvasHeight);
                ctx.lineTo(position, canvasHeight - amp);
                ctx.stroke();
            }

            const maxPos = canvasRef.current!.width - barWidth - barGap;

            if(position > maxPos){
                ctx.globalCompositeOperation = "copy";
                ctx.drawImage(ctx.canvas, -barWidth-barGap, 0);
                ctx.globalCompositeOperation = "source-over";
                draw(maxPos, value);
            } else {
                draw(position, value);
            }

   
        }
    },[ctx, canvasRef]);

    const addData = useCallback((data: number) => {
        setAudioArray([...audioArray, data]);
        const audioLength = audioArray.length;
        const value = audioArray[audioLength -1];
        
        if(ctx){
            addBar(value, audioLength);
        }
    },[audioArray, ctx, addBar]);

    useEffect(()=>{
        if(data instanceof Float32Array){
            if(ctx){
                ctx.clearRect(0,0,ctx.canvas.width, ctx.canvas.height);
            }
            
            const dataWidth = data.length * barWidth + data.length;
            if(canvasRef.current && dataWidth > canvasRef.current.width){
                const mod = Math.floor(dataWidth/canvasRef.current.width);

                data.reduce(({avg, n}, currentValue, currentIndex) => {
                    const avg2 = (Math.abs(currentValue) + n * avg) / (n + 1);
                    const n2 =  n+1;

                    if(n2 % mod === 0){
                        addBar(avg2, currentIndex/mod);
                        return {
                            avg: 0,
                            n: 0
                        }
                    }
                    return {
                        avg: avg2,
                        n: n2
                    }   
                }, {avg: 0, n:0});
            } else {
                data.forEach((val, index) => {
                    addBar(val, index);
                });
            }
        } else if(!!data) {
            addData(data);
        }
    },[data, addBar, ctx])

    return {containerRef, canvasRef, setData};
}

const useCanvas = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [ctx, setCtx] = useState<CanvasRenderingContext2D|null>(null);
   
    useEffect(() => {
        const ctx = canvasRef.current?.getContext("2d");
        setCtx(ctx!);

        if(containerRef.current){
            canvasRef.current!.width = containerRef.current.offsetWidth;
            canvasRef.current!.height = containerRef.current.offsetWidth * 0.3;
        }
        containerRef.current?.addEventListener("resize", () => {
            canvasRef.current!.width = containerRef.current!.offsetWidth;
            canvasRef.current!.height = containerRef.current!.offsetWidth * 0.3;
        });
    },[canvasRef])

    useEffect(()=>{
        if(containerRef.current){
            containerRef.current.style.width = "100%";
        }
    },[containerRef])

    return {containerRef, canvasRef, ctx};
}

export default useAudioOnCanvas;
import { useLocizeString } from "../../../../../../state/hooks/utils";
import { TranslationConfig } from "../../translationConfig";

export enum TranslationKey {
    LINE_1,
    LINE_2,
    YES_LABEL,
    NO_LABEL
}

const translationConfigs: {[key in TranslationKey]: TranslationConfig} = {
    [TranslationKey.LINE_1]: {
        key: "question_line_1",
        fallback: "Alle minner som sendes inn på minner.no blir brukt til forskning av oss på museet"
    },
    [TranslationKey.LINE_2]: {
        key: "question_line_2",
        fallback: "Du kan selv velge om bidraget ditt skal være tilgjengelig for alle på minner.no, eller kun for museet"
    },
    [TranslationKey.YES_LABEL]: {
        key: "yes_label",
        fallback: "Det kan være synlig for alle"
    },
    [TranslationKey.NO_LABEL]: {
        key: "no_label",
        fallback: "Det skal kun være tilgjengelig for museet"
    },
}

const useTranslatedString = (key: TranslationKey) => {
    const config = translationConfigs[key];
    return useLocizeString("authorInput", config.key, config.fallback);
}

export default useTranslatedString;
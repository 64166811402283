import React, {useContext} from "react";
import styles from './styles.module.scss';

import VideoRecorder from "./videoRecorder/videoRecorder";
import { MediaRecorderService } from "./mediaRecorderService";
import { MediaControllerService, MediaMode } from "./mediaControllerService";
import { useObservable } from "../../../../state/hooks/useObservable/useObservable";
import Button from "../../../../components/button/Button";
import CameraToggleButton from "./cameraToggleButton/CameraToggleButton";
import { TextInputService } from "../input/text/TextInputService";
import AudioRecorder from "./audioRecorder/AudioRecorder";
import { useLocizeString } from "../../../../state/hooks/utils";

const MediaController = () => {

    const context = useContext(MediaRecorderContext);
    const isVideo = useObservable(context.controller?.mediaMode) === MediaMode.VIDEO;

    const audioLabel = useLocizeString("mediaRecorder", "disableVideo", "Kun lyd");
    const videoLabel = useLocizeString("mediaRecorder", "enableVideo", "Video");

    const toggleType = () => {
        context.controller?.toggleMediaMode();
    }

    return (
        <div className={styles.container}>
            {isVideo  
                ? <VideoRecorder />
                : <AudioRecorder />
            }
            <div className={styles.videoBar}>
                <Button materialIcon="close" onClick={() => context.inputService?.closeRecorder()}/>
                <Button 
                    materialIcon={isVideo ? "videocam_off" : "videocam"} 
                    label={isVideo ? audioLabel : videoLabel} 
                    onClick={toggleType}
                />
                {isVideo && <CameraToggleButton />}
            </div>
        </div>
    );
}

type MediaRecorderContextType = {
    recorder: MediaRecorderService,
    controller: MediaControllerService,
    inputService: TextInputService,
    partIndex: number
}

export const MediaRecorderContext = React.createContext<Partial<MediaRecorderContextType>>({})

const MediaRecorder = (props: {inputService: TextInputService, partIndex: number}) => {
    const mediaRecorder = new MediaRecorderService();

    const context = {
        recorder: mediaRecorder,
        controller: new MediaControllerService(mediaRecorder),
        inputService: props.inputService,
        partIndex: props.partIndex
    };

    return (
        <MediaRecorderContext.Provider value={context}>
            <MediaController></MediaController>
        </MediaRecorderContext.Provider>
    )

}

export default MediaRecorder;
import { BehaviorSubject } from "rxjs";

export const getAbout = () => {
    const $about = new BehaviorSubject<{[i18n: string]: string}[]>([]);

    fetch(`${window._env_.REACT_APP_MEMORY_API}/info/about-info`)
        .then((response) => {
            if(response.ok){
                response.json().then(info => {
                    $about.next(info);
                })
            }
        })
        .catch((error: any) => {});

    return $about;
}
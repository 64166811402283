import Question from "./components/question/question"
import styles from './styles.module.scss';

import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { MemoryService } from "./memoryService";
import { useObservable } from "../../state/hooks/useObservable/useObservable";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import SubmitDialogue from "./components/submitMemory/SubmitDialogue";
import Button from "../../components/button/Button";
import { AppContext } from "../../App";
import Dialogue from "../../components/dialogue/Dialogue";
import Sequencer from "../../components/sequencer/Sequencer";
import { useLocizeString } from "../../state/hooks/utils";
import submitReducer, { initialState, SubmitAction, SubmitState } from "./components/submitMemory/state/reducer";
import {copyToClipboard} from "../../utils/utils";

const SubmittedMessage = () => {
    const appContext = useContext(AppContext);
    const isLoggedIn = useObservable(appContext.isLoggedIn$);
    
    const history = useHistory();
    const memoryContext = useContext(MemoryContext);

    const reset = () => memoryContext?.reInitWizard?.();
    const close = () => history.replace(history.location.pathname);
    const navigateToMyPage = () => history.push("/myPage");

    const generateShareUrl = () => {
        if(window.location.hostname === "localhost") {
            const port = window.location.port
            const identifier = window.location.href.split('?')[0].split('/')[window.location.href.split('?')[0].split('/').length - 1]
            const shareUrl = "localhost:" + port + '/share?identifier=' + identifier
            console.log("SHARE URL: ", shareUrl)
            if (shareUrl) {
                return copyToClipboard(shareUrl)
            } else {
                console.error('Has no share url...')
            }
        } else {
            const environmentUrl = window.location.hostname
            const identifier = window.location.pathname.replace('/', '')
            const shareUrl = environmentUrl + '/share?identifier=' + identifier
            console.log("SHARE URL: ", shareUrl)
            if (shareUrl) {
                return copyToClipboard(shareUrl)
            } else {
                console.error('Has no share url...')
            }
        }
    }

    const thankYouMessage = useLocizeString("delivery","thankYou","Tusen takk for ditt svar");
    const completedInfo = useLocizeString("delivery","completedInfo", "Du kan se svaret på mine sider");
    const emailInfo = useLocizeString("delivery","emailInfo", "Husk å åpne eposten fra oss, for å bekrefte innsendelsen")
    
    const submittedText = [
        thankYouMessage,
        isLoggedIn ? completedInfo : emailInfo,
    ];

    const submittedOptions = (
        <Sequencer elements={[
            <Button className={styles.button} label="Del minneinnsamling" onClick={generateShareUrl} key="copyUrl"/>,
            <Button className={styles.button} label="Send inn nytt minne" onClick={reset} key="sendNew" />,
            <Button className={styles.button} label="Se på min side" onClick={navigateToMyPage} key="myPage" hidden={!isLoggedIn}/>,
            <Button className={styles.button} label="Lukk" onClick={close} key="close"/>,
        ]} />
    );

    return (
        <Dialogue questions={submittedText} answer={submittedOptions} animate       />
    )
}

const MemoryDisplay = () => {
    const context = useContext(MemoryContext) as MemoryContextType;
    const questions = useObservable(context.service.questions$);
    const completed = useObservable(context.service.completed$);
    const editing = useObservable(context.service.editing$);

    const contentRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={contentRef} className={styles.conversation}>
            {questions && questions.length > 0 && questions.map((data, index) => (
                <Question key={data.uuid} data={data} animate={index === questions.length - 1 && !completed && !editing}/>
            ))}
            {(completed && !editing) &&  <SubmitDialogue />}
        </div>
    );
}

const DisplayOrSubmitted = () => {
    const memoryService = useContext(MemoryContext).service;
    const isSubmitted = useObservable(memoryService?.submitted$);

    return (
        isSubmitted ? <SubmittedMessage /> : <MemoryDisplay /> 
    );
}

export type MemoryContextType = {
    service: MemoryService,
    submitState: SubmitState,
    submitDispatch: React.Dispatch<SubmitAction>,
    reInitWizard: () => void
}

export const MemoryContext = React.createContext<Partial<MemoryContextType>>({})

const MemoryWizard = (props: {topic_id: string, uuid?: string}) => {
    const appContext = useContext(AppContext);
    const [submitState, submitDispatch] = useReducer(submitReducer, initialState());
    const memoryService = new MemoryService(appContext);

    const [, updateState] = React.useState({});
    const reInitWizard = useCallback(() => {updateState({})}, []);

    const context: MemoryContextType = {
        service: memoryService,
        submitState,
        submitDispatch,
        reInitWizard
    };
    
    useEffect(() => {
        memoryService.init(props.topic_id, props.uuid, submitDispatch);
    },[memoryService, props.topic_id, submitDispatch]);

    useEffect(() => {
        return () => memoryService.unsubscribe();
    },[])

    return (
        <MemoryContext.Provider value={context}>
            <DisplayOrSubmitted />
        </MemoryContext.Provider>
    );
}

const MemoizedMemoryWizard = React.memo(MemoryWizard);

export default MemoizedMemoryWizard;
import React from "react";
import { QuestionData } from "../../memoryService";
import Coordinate from "../input/coordinate/Coordinate";
import TextAnswer from "../answer/TextAnswer";
import LocationAnswer from "../answer/LocationAnswer";
import CoordinateAnswer from "../answer/CoordinateAnswer";
import DateAnswer from "../answer/DateAnswer";
import SelectAnswer from "../answer/SelectAnswer";
import TextInput from "../input/text/TextInput";
import DateRange from "../input/dateRange/DateRange";
import LocationInput from "../input/locationInput/LocationInput";
import Select from "../input/select/Select";
import DateInput from "../input/date/DateInput";

export enum QuestionType {
    TEXT = "Input",
    TEXT_AREA = "Textarea",
    RADIO = "Radio",
    CHECKBOX = "Checkbox",
    SELECT = "Select",
    DATE = "Single date",
    DATE_RANGE = "Date range",
    INFO = "Form help text",
    TEXT_AND_MEDIA = "Media",
    WYSIWYG = "WYSIWYG",
    TITLE = "Title",
    SUBJECTS = "Subjects",
    COORDINATE = "Coordinate",
    COORDINATES = "Coordinates",
    LOCATION = "Location",
    YOUTUBE = "YouTube",
    SOUNDCLOUD = "SoundCloud"
}

export const getInputComponent = (question: QuestionData) => {
    switch (question.type.name){
        case(QuestionType.TEXT):
        case(QuestionType.TEXT_AREA):
            return (
                <TextInput />
            ); 
        case(QuestionType.TEXT_AND_MEDIA):
        case(QuestionType.WYSIWYG):
            return (
                <TextInput showMedia />
            );
        case QuestionType.SELECT:
        case QuestionType.RADIO:
            return (
                <Select items={question.options!} />
            ); 
        case QuestionType.CHECKBOX:
            return (
                <Select multiselect items={question.options!} />
            ); 
        case QuestionType.LOCATION:
            return (
                <LocationInput />
            );
        case QuestionType.COORDINATE:
            return (
                <Coordinate />
            );
        case QuestionType.COORDINATES:
            return (
                <Coordinate />
            );
        case QuestionType.DATE:
            return (
                <DateInput />
            );
        case QuestionType.DATE_RANGE:
            return (
                <DateRange /> 
            );
        default:
            return (
                <TextInput />
            ); 
    }
}

export const getAnswerComponent = (question: QuestionData) => {
    switch (question.type.name){
        case(QuestionType.TEXT):
        case(QuestionType.TEXT_AREA):
        case(QuestionType.TEXT_AND_MEDIA):
        case(QuestionType.WYSIWYG):
            return (
                <TextAnswer answer$={question.answer$} />
            );
        case QuestionType.SELECT:
        case QuestionType.RADIO:
        case QuestionType.CHECKBOX:
            return (
                <SelectAnswer questionData={question} />
            ); 
        case QuestionType.LOCATION:
            return (
                <LocationAnswer answer$={question.answer$}/>
            );
        case QuestionType.COORDINATE:
        case QuestionType.COORDINATES:
            return (
                <CoordinateAnswer answer$={question.answer$} />
            );
        case QuestionType.DATE:
        case QuestionType.DATE_RANGE:
            return (
                <DateAnswer answer$={question.answer$}/> 
            );
        default:
            return (
                <TextAnswer answer$={question.answer$} />
            ); 
    }
}
import React, { useContext, useRef, useState } from "react";
import Button from "../../../../../../components/button/Button";
import Dialogue from "../../../../../../components/dialogue/Dialogue";
import Sequencer from "../../../../../../components/sequencer/Sequencer";
import { MemoryContext } from "../../../../memoryWizard";
import { useTranslatedString, EmailKey } from "./translationConfig";

import cn from "classnames";

import styles from "./styles.module.scss";
import { SubmitActionType } from "../../state/reducer";

const RegisterEmail = () => {

    const context = useContext(MemoryContext);
    
    const infoLine1 = useTranslatedString(EmailKey.emailInfo1);
    const infoLine2 = useTranslatedString(EmailKey.emailInfo2);
    const emailPlaceHolder = useTranslatedString(EmailKey.emailPlaceHolder);
    const continueLabel = useTranslatedString(EmailKey.continueLabel);

    const info = [
        infoLine1,
        infoLine2
    ];

    const EmailInput = () => {
        const inputRef = useRef<HTMLInputElement>(null);
        const [email, setEmail] = useState("");
        const [isValid, setIsValid] = useState(false);

        const emailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.currentTarget.value);
            setIsValid(event.currentTarget.value !== "" && event.currentTarget.checkValidity());
            event.preventDefault();
        }
    
        const handleSubmit = () => {
            context.submitDispatch?.({type: SubmitActionType.SET_EMAIL, payload: email});
        }

        const inputParts = [
            <div 
                className={styles.answer} 
                key="input"
                onAnimationEnd={(event)=>{inputRef.current?.focus()}} 
            >
                <input 
                    type="email" 
                    ref={inputRef}
                    value={email}
                    placeholder={emailPlaceHolder}
                    onInput={emailChanged}
                />
                <Button 
                    materialIcon="keyboard_return"
                    type="submit"
                />
            </div>,
            <Button 
                className={cn(styles.submit, isValid && styles.disabled)} 
                label={continueLabel} 
                disabled={!isValid}
                key="submit"
                onClick={handleSubmit}
            />
        ];
    
        return (
            <form onSubmit={handleSubmit}>
                <Sequencer elements={inputParts} />
            </form>
        );
    }

    

    return (
        <Dialogue questions={info} answer={React.createElement(EmailInput)} animate={true}/>       
    )
}

export default RegisterEmail;
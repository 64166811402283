
import React, { useEffect, useState, useRef, useMemo } from "react";
import cn from "classnames";
import Button from "../../../../../components/button/Button";
import SubmitButton from "../../../../../components/button/SubmitButton";
import Sequencer from "../../../../../components/sequencer/Sequencer";

import styles from "./styles.module.scss";
import { useContext } from "react";
import { MemoryContext } from "../../../memoryWizard";
import { textValuesFromCurrentAnswer } from "../utils";
import { initializeMap } from "./utils";
import { useLocizeString } from "../../../../../state/hooks/utils";


const Coordinate = () => {
    const mapRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [coordinates, setCoordinates] = useState<string[]>([]);

    const memoryContext = useContext(MemoryContext);
    const initialCoords = useMemo(() => textValuesFromCurrentAnswer(memoryContext.service),[memoryContext.service]);

    const [focus, setFocus] = useState(false);
    
    useEffect(()=> {
        if(focus){
            searchRef.current?.focus();
        }
    }, [focus]);

    useEffect(() => {
        const callbacks = initializeMap(
            initialCoords,
            mapRef,
            searchRef,
            setIsValid,
            setCoordinates,
        );
        return () => {
            callbacks.forEach(callback => callback())
        }
    },[mapRef, searchRef, initialCoords]);

    const submitHander = (event: React.FormEvent) => {
        event.preventDefault();
    };

    const send = () => {
        if(coordinates && coordinates.length){
            memoryContext.service?.addAnswerWithText(coordinates);
        } else {
            memoryContext.service?.addEmptyAnswer();
        }
    };

    const placeHolderText = useLocizeString("memoryForm","coordinateSearchPlaceholder", "Adresse eller sted");

    const elements = [
        <div 
            className={styles.container} 
            key="form"
            onAnimationEnd={()=>{setFocus(true)}} 
        >
            <div className={styles.map} ref={mapRef}></div>
            <form className={styles.searchBar} onSubmit={submitHander}>
                <input 
                    className={styles.searchBox} 
                    ref={searchRef} 
                    placeholder={placeHolderText}
                />
                <label className={cn(styles.searchIcon, "material-icons")}>search</label>
                <Button 
                    className={cn(styles.returnIcon, "material-icons")} 
                    type="submit" 
                    materialIcon="keyboard_return"
                    tabIndex={-1}
                />
            </form>
        </div>,
        <SubmitButton isValid={isValid} onClick={send} key="submit"></SubmitButton>
    ];

    return (
        <Sequencer elements={elements} />
    );
}
export default Coordinate;
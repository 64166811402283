import { useLocizeString } from "../../../../../../state/hooks/utils";
import { TranslationConfig } from "../../translationConfig";

export enum EmailKey {
    emailInfo1 = "emailInfo1",
    emailInfo2 = "emailInfo2",
    emailPlaceHolder = "emailPlaceholder",
    continueLabel = "continueLabel"
}

const isEmailKey = (key: any): key is EmailKey => {
    return (Object.values(EmailKey).indexOf(key) !== -1);
}

export enum LoginKey {
    loginInfo1 = "loginInfo1",
    loginInfo2 = "loginInfo2",
    loginInfo3 = "loginInfo3",
    loginLabel = "loginLabel",
    registerEmailLabel = "registerEmail",
}

const isLoginKey = (key: any): key is LoginKey => {
    return (Object.values(LoginKey).indexOf(key) !== -1);
}

export type TranslationKey = EmailKey | LoginKey;

const emailConfig: {[key in EmailKey]: TranslationConfig} = {
    [EmailKey.emailInfo1]: {
        key: "infoLine1",
        fallback: "Tast inn din epost, så vil du motta en melding fra oss når du sender inn minnet",
    },
    [EmailKey.emailInfo2]: {
        key: "infoLine2",
        fallback: "Meldingen inneholder en lenke som du må trykke på for å bekrefte innsendelsen"
    },
    [EmailKey.emailPlaceHolder]: {
        key: "emailPlaceholder",
        fallback: "Skriv inn din epost"
    },
    [EmailKey.continueLabel]: {
        key: "continueWithEmail",
        fallback: "Fortsett med epost"
    }
}

const loginConfig: {[key in LoginKey]: TranslationConfig} = {
    [LoginKey.loginInfo1]: {
        key: "infoLine1",
        fallback: "For å sende inn minnet trenger vi at du identifiserer deg"
    },
    [LoginKey.loginInfo2]: {
        key: "infoLine2",
        fallback: "Du kan logge inn med din eKultur-bruker, eller med facebook eller google"
    },
    [LoginKey.loginInfo3]: {
        key: "infoLine3",
        fallback: "Hvis du ikke ønsker å logge inn kan du registrere en epost-adresse"
    },
    [LoginKey.loginLabel]: {
        key: "loginButtonLabel",
        fallback: "Logg inn"
    },
    [LoginKey.registerEmailLabel]: {
        key: "registerEmailButtonLabel",
        fallback: "Registrer med epost"
    }
}

const getConfig = (key: TranslationKey): [nameSpace: string, config: TranslationConfig] => {
    if(isEmailKey(key)){
        const config = emailConfig[key];
        return ["registerEmail", config];
    } else if(isLoginKey(key)){
        const config = loginConfig[key];
        return ["login", config];
    }
    return ["error", {key: "error", fallback: "translation error"}];
}

export const useTranslatedString = (key: TranslationKey) => {
    const [nameSpace, config] = getConfig(key);
    return useLocizeString(nameSpace, config.key, config.fallback);  
}
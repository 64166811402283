import { useEffect, useRef } from "react";
import useSequence from "./useSequence";
import styles from "./styles.module.scss"
import cn from "classnames";

const Sequencer = (props: {elements: JSX.Element[], skipAnimation?: boolean}) => {

    const sequence = useSequence(props.elements, props.skipAnimation);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(()=> {
        ref.current?.scrollIntoView();
    },[sequence])

    return (
        <div className={cn(styles.sequencer,!props.skipAnimation && styles.animated)} ref={ref}>
            {sequence}
        </div>
    );
}

export default Sequencer;

import { useContext } from "react";
import { AppContext } from "../../App";
import { Header } from "../../header/Header";
import { useObservable } from "../../state/hooks/useObservable/useObservable";
import MemoryGrid from "./memoryGrid/MemoryGrid";
import PageMenu from "./PageMenu";

import styles from "./styles.module.scss";

const MyPage = () => {
    const appContext = useContext(AppContext);
    const userInfo = useObservable(appContext?.userInfo$); 

    return (
        <MemoryGrid />
    )
        
}

export default MyPage;
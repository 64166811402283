import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BehaviorSubject } from "rxjs";
import { deleteResponse, MemoryResponse, ResponseMedia } from "../../../api/response";
import { getUserResponses, UserResponses } from "../../../api/user";
import { AppContext } from "../../../App";
import Button from "../../../components/button/Button";
import DmsImage from "../../../components/image/DmsImage";
import { useApi } from "../../../state/hooks/useApi/useApi";
import { useObservable } from "../../../state/hooks/useObservable/useObservable";
import { useLocizeString } from "../../../state/hooks/utils";

import styles from "./styles.module.scss";

const locizeNamespace = "myMemories";

const MemoryGrid = () => {
    
    const appContext = useContext(AppContext);
    const [isLoading, setIsLoading] = useState<boolean>(true)
 
    const i18n = useObservable(appContext.i18n$);

    const nameLabel = useLocizeString(locizeNamespace, "nameLabel", "Publisert som");
    const dateLabel = useLocizeString(locizeNamespace, "dateLabel", "Publisert");
    const deleteLabel = useLocizeString(locizeNamespace, "deleteLabel", "Slett");
    const editLabel = useLocizeString(locizeNamespace, "editLabel", "Endre");

    const confirmMessage = useLocizeString(locizeNamespace, "confirmMessage", "Sikker på at du vil slette dette minnet?")

    const userResponses = useApi(getUserResponses)

    useEffect(() => {
        if(userResponses)
            setIsLoading(false);
    },[userResponses])

    const getMore = () => {
        setIsLoading(true);
        getUserResponses(userResponses?.count);
    }
    
    const MediaDisplay = (props: {response: MemoryResponse}) => {

        const { response } = props;
        const imageId = response.image_dms_id;

        if(!imageId){
            return (
                <div className={styles.mediaDisplay}></div>
            );
        }

        return (
            <div className={styles.mediaDisplay}>
                <DmsImage dmsId={imageId} />
            </div>
        );
    }

    const ResponseDisplay = ({response$}: {response$: BehaviorSubject<MemoryResponse>}) => {
        const response = useObservable(response$);

        return (
            <>
                <div className={styles.gridItem} key={response.uuid}>
                    <div className={styles.imageWrapper}>
                        <MediaDisplay response={response} />
                        <div className={styles.textWrapper}>
                            <h3>{response.topic.name_i18n?.[i18n] || response.topic.name}</h3>
                        </div>
                    </div>
                    <div className={styles.infoRow}>
                        <div className={styles.authorRow}>
                            <div className={classNames("material-icons",styles.icon)}>account_circle</div>
                            <div>
                                <span className={styles.label}>{nameLabel}</span>
                                <span>{response.contributor?.display_name || "Anonym"}</span>
                            </div>
                        </div>
                        <div>
                            <span className={styles.label}>{dateLabel}</span>
                            <span>{new Date(response.created!).toLocaleDateString()}</span>
                        </div>
                    </div>
                    <div className={styles.editRow}>
                        {response.v2 && 
                            <Link to={`response/${response.uuid}/edit`}>
                                <span className="material-icons">edit</span>
                                <label>{editLabel}</label>
                            </Link>
                        }
                        <Button value={response.uuid} onClick={()=>deleteMemory(response.uuid)} label={deleteLabel} materialIcon="delete" />
                    </div>
                </div>
            </>
        )
    }

    const deleteMemory = (uuid?: string) => {
        if(uuid && window.confirm(confirmMessage)){
            deleteResponse(uuid);
        }
    }

    const showing = useLocizeString(locizeNamespace, "showing", "Viser");
    const of = useLocizeString(locizeNamespace, "of", "av");
    const memories = useLocizeString(locizeNamespace, "memories", "minner");
    const moreLabel = useLocizeString(locizeNamespace, "moreLabel", "Vis flere");
    
    return (
        <>
            <h2>
                {showing} {userResponses?.count} {of} {userResponses?.total_count} {memories}
            </h2>
            <div className={styles.memoryGridWrapper}>
                {
                    userResponses?.responseList?.map((val, index) => (
                        <ResponseDisplay response$={val} key={index} />
                    ))
                }
            </div>
            {isLoading 
                ? <div className={styles.loader}/>
                : <Button
                    hidden={!userResponses || userResponses.count >= userResponses.total_count} 
                    label={moreLabel} 
                    className={styles.moreButton} 
                    materialIcon="keyboard_arrow_down"
                    onClick={getMore} 
                />
            }
        </>
    )
}

export default MemoryGrid;
import { useEffect, useRef, useState } from "react";
import { FormUserInfo } from "../../../../../components/userInfo/SubmitUserInfo";

const useHasUserInfo = (userInfo: FormUserInfo|undefined) => {
    const [has, setHas] = useState<boolean>(false);

    useEffect(()=>{
        if(
            userInfo?.year_of_birth !== undefined
            && userInfo?.birthplace !== undefined
            && userInfo?.allow_newsletter !== undefined
            && userInfo?.allow_newsletter !== null
        ){
            setHas(true);
        } else {
            setHas(false);
        }
    },[userInfo]);

    return has;
}

export default useHasUserInfo;
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { kitFetchExternal } from "@ekultur/fetch";
import { getFromCache } from "./cache";
import mockData from "../assets/mockdata/topic-stub.json";

export const getSchoolTopics = () => {
    return getTopics().pipe(
        map(topics => topics?.filter(item => item.school_topic===true))
    );
}

export const getTopics = () => {
    const topics = getFromCache("topics");
    if(!topics.value){
        requestTopics(0);
    }
    return topics as BehaviorSubject<any[]>;
}

const requestTopics = (offset: number) => {
    fetch(`${window._env_.REACT_APP_MEMORY_API}/topics/?limit=20`, {
        method: "GET",
        headers: {
            "Accept": "application/json"
        }
    })
        .then(response => response.json())
        .then(data => getFromCache("topics").next(data.items));
}

export const getTopic = (uuid: string) => {
    if(uuid === "demo"){
        return new BehaviorSubject(mockData);
    }
    const topic = getTopicFromCache(uuid);
    if(!topic.value){
        requestTopic(uuid);
    }
    return topic;
}

const getTopicFromCache = (uuid: string) => 
    getFromCache(`topic|${uuid}`);

const requestTopic = (uuid: string) => {
    fetch(`${window._env_.REACT_APP_MEMORY_API}/topic/${uuid}`)
        .then(response => response.json())
        .then(data => {
            getTopicFromCache(uuid).next(data);
        });
}

export const getIsCollector = (uuid: string) => {
    const $isCollector = new BehaviorSubject(false);

    kitFetchExternal(`${window._env_.REACT_APP_MEMORY_API}/topic/${uuid}/collector`)
        .then((data: boolean) => {
            $isCollector.next(data);
        })
        .catch((error: any) => {});

    return $isCollector;
}
import { Loader } from "@googlemaps/js-api-loader";
import React, { useEffect, useRef } from "react";
import { BehaviorSubject } from "rxjs";
import { useObservable } from "../../../../state/hooks/useObservable/useObservable";
import { ItemAnswer } from "../../memoryService";
import styles from "./styles.module.scss";

const CoordinateAnswer = (props: {answer$: BehaviorSubject<ItemAnswer|undefined>}) => {
    const mapsLoader = new Loader({
        apiKey: "AIzaSyDvmK6SWv9gXlhy4yUB1Axkh_0Xu3v_lZ0",
        libraries: ["places"]
    });

    const { answer$ } = props;
    const coordinates = useObservable(answer$)?.values;
    
    const mapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        mapsLoader.load().then(() => {
            const geocoder = new google.maps.Geocoder();
            const bounds = new google.maps.LatLngBounds();

            if(mapRef.current){
                const map = new google.maps.Map(mapRef.current, {
                    disableDefaultUI: true,
                    gestureHandling: "none",
                    zoomControl: false,
                });
    
                coordinates?.forEach((response) => {
                    const latLng = JSON.parse(response.value);
                    geocoder.geocode({
                        location: latLng,              
                    }, (results, status) => {
                        if(status === "OK"){
                            const place = results?.find(result => result.types.includes("plus_code"));                       
                            if(place) {
                                new google.maps.Marker({
                                    position: place.geometry?.location,
                                    map
                                });
    
                                if (place.geometry?.viewport) {
                                    bounds.union(place.geometry.viewport);
                                } else if(place.geometry?.location){
                                    bounds.extend(place.geometry.location);
                                }
                                map.fitBounds(bounds);
                            }
                        }
                    });
                });
            }
        });
    },[mapRef, coordinates]);

    return (
        <div className={styles.answerContainer}>
            <div className={styles.map} ref={mapRef}></div>
        </div>
    );
}

export default CoordinateAnswer;
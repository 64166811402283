import { useContext, useMemo } from "react";
import { getAbout } from "../../api/info";
import { getTopics } from "../../api/topics"
import { AppContext } from "../../App";
import { Header } from "../../header/Header";
import { useApi } from "../../state/hooks/useApi/useApi";
import { useObservable } from "../../state/hooks/useObservable/useObservable"
import { useCurrentMemoryLocale } from "../../state/hooks/utils";
import TopicList from "./components/TopicList";

import styles from './styles.module.scss';

const FrontPage = () => {
    const context = useContext(AppContext);
    const locale = useCurrentMemoryLocale(context.i18n$);

    const info = useApi(getAbout)

    return (
        <div className={styles.frontPage}>
            <div className={styles.top}>
            </div>
            {info?.map(val => (
                <section dangerouslySetInnerHTML={{__html: val[locale]!}}></section>
            ))}
            <Header />
        </div>
    );
}

export default FrontPage
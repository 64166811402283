import { useState, useRef, useEffect, useCallback } from "react";
import { getPreviewUrl } from "../../api/upload";
import { MediaStatus } from "../../components/mediaStatus/mediaStatus";

export const useDmsMedia = (src: string|undefined, dmsId: string|undefined, mime_type: string) => {
    const [mediaSrc, setSrc] = useState<string|undefined>(src);
    const [mediaStatus, setStatus] = useState<MediaStatus>(MediaStatus.DONE);
    const [showErrorMessage, setShow] = useState<boolean>(false);

    const mounted = useRef(false);

    useEffect(()=>{
        mounted.current = true;

        return () => {
            mounted.current = false
        };
    })

    useEffect(() => {
        if(!mime_type.startsWith("image") && !src && dmsId){
            getPreviewUrl(dmsId).then(url => {
                setSrc(url);
            }).catch(()=>{});
        }
    }, [src, dmsId, mime_type])

    /*
    Load error will always occur when loading media answer from db, as no src is stored.
    The app defaults to use src to be able to use the file stored locally as objectURL (in memory)
    when uploading. Dms src is also stored locally although it can expire. OnError event is used 
    to ensure it will fall back to use dmsId
    */
    const onError = useCallback(() => {
        setShow(true);

    
        if(dmsId){
             //View falls back to use DmsImage for images
            if(mime_type.startsWith("image")){
                setSrc(undefined);
            }
            // Same src means we are using a locally stored src that has expired
            if(mediaSrc === src){
                // Preview url will not be returned before the upload is processed
                setStatus(MediaStatus.PROCESSING);
                
                //Will poll DMS until status is ready
                getPreviewUrl(dmsId).then(url => {
                    if(mounted.current){
                        // Start to load media from DMS
                        setStatus(MediaStatus.LOADING); 
                        setSrc(url);
                    }
                // Will fail if DMS returns anything but processing or ready
                }).catch(() => {
                    if(mounted.current){
                        setStatus(MediaStatus.NOT_AVAILABLE);
                    }
                })
            } else {
                // Already tried to get fresh playback url, but still fails to load
                setStatus(MediaStatus.NOT_AVAILABLE);
            }
        } else {
            // No dmsId means either the upload failed or was interrupted
            setStatus(MediaStatus.NOT_UPLOADED);
        }
    },[src, dmsId, mediaSrc, mime_type]);


    const onLoad = () => {
        setShow(false);
    }

    return {mediaSrc, showErrorMessage, mediaStatus, onError, onLoad};
}
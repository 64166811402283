import styles from './styles.module.scss';

import { ItemAnswer } from "../../memoryService";
import AnswerPart from './AnswerPart';
import { BehaviorSubject } from 'rxjs';
import { useObservable } from '../../../../state/hooks/useObservable/useObservable';

const TextAnswer = (props: {answer$: BehaviorSubject<ItemAnswer|undefined>}) => {
    const { answer$ } = props;
    const answer = useObservable(answer$);

    const sortedTextAndMediaList = answer?.media && answer?.values
        ? [...answer.values, ...answer.media].sort((a,b) => a.orderNumber - b.orderNumber)
        : answer?.values
            ? answer.values
            : answer?.media
    ;


    return (
        <div className={styles.answerContainer}>
            {sortedTextAndMediaList?.map(val => (
                "mime_type" in val
                    ? <AnswerPart text={val.description} mediaEntry={val} key={val.orderNumber + val.mime_type}/>
                    : <AnswerPart text={val.value} key={val.orderNumber + val.value}/>
            ))}
        </div>
    )
}

export default TextAnswer;
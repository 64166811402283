import { useContext } from "react";
import { AppContext } from "../../../../App";
import { useObservable } from "../../../../state/hooks/useObservable/useObservable";
import { useLocizeString } from "../../../../state/hooks/utils";
import { QuestionData } from "../../memoryService";
import AnswerPart from "./AnswerPart";

import styles from "./styles.module.scss";

const SelectAnswer = (props: {questionData: QuestionData}) => {
    
    const appContext = useContext(AppContext);
    const i18n = useObservable(appContext.i18n$);

    const options = props.questionData.options;
    const selectedOptions = useObservable(props.questionData.answer$)?.values;
    const notFoundLabel = useLocizeString("memoryForm", "errorSelectedNotFound", "Valg ikke funnet");

    const findOptionValue = (uuid: string) => {
        const option = options?.find(val => val.uuid === uuid);
        return option ? (option.label_i18n?.[i18n] || option.label) : notFoundLabel
    };

    return (
        <div className={styles.answerContainer}>
            {selectedOptions?.map(val => (
                <AnswerPart text={findOptionValue(val.value)} key={val.value}/>
            ))}
        </div>
    )
}

export default SelectAnswer;
import styles from './styles.module.scss';

export enum MediaStatus {
    UPLOADING,
    PROCESSING,
    LOADING,
    DONE,
    NOT_AVAILABLE,
    NOT_UPLOADED
}

const uploadingMessage = (
    <div className={styles.statusMessage}>
        <div className={styles.loader}></div>
        <span>Laster opp..</span>
    </div>
);

const processingMessage = (
    <div className={styles.statusMessage}>
        <div className={styles.loader}></div>
        <span>Media behandles..</span>
    </div>
);

const loadingMessage = (
    <div className={styles.statusMessage}>
        <div className={styles.loader}></div>
        <span>Henter media..</span>
    </div>
);

const notAvailableMessage = (
    <div className={styles.statusMessage}>
        <div className="material-icons">warning</div>
        <span>Kan ikke hente media</span>
    </div>
);

const notUploadedMessage = (
    <div className={styles.statusMessage}>
        <div className="material-icons">error</div>
        <span>Opplasting mislykkes, venneligst prøv igjen</span>
    </div>
);

const doneMessage = (
    <div className={styles.statusMessage}>
        <span>Fullført</span>
    </div>
);

const messageMap = {
    [MediaStatus.PROCESSING]: processingMessage,
    [MediaStatus.UPLOADING]: uploadingMessage,
    [MediaStatus.LOADING]: loadingMessage,
    [MediaStatus.NOT_AVAILABLE]: notAvailableMessage,
    [MediaStatus.NOT_UPLOADED]: notUploadedMessage,
    [MediaStatus.DONE]: doneMessage
}

const StatusMessage = ({status}: {status: MediaStatus}) => messageMap[status];;

export default StatusMessage;
import React, { useContext, useEffect, useRef } from "react";
import { useObservable } from "../../../../../state/hooks/useObservable/useObservable";
import { MediaRecorderContext } from "../mediaRecorder";
import { RecordingState } from "../mediaRecorderService";
import styles from "./styles.module.scss";

type VideoDisplayProps = {
    setRef?: (element: HTMLVideoElement | null) => void,
}

const VideoDisplay = (props: VideoDisplayProps) => {
    const context = useContext(MediaRecorderContext);
    const videoSource = useObservable(context.recorder?.mediaSource$);
    const recordingState = useObservable(context.recorder?.recorderState$);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    
    useEffect(() => {
        if(videoSource?.isObject && videoRef.current){
            videoRef.current.removeAttribute("src");
            videoRef.current.srcObject = videoSource.src;
        } else if(videoRef.current){
            videoRef.current.srcObject = null;
            videoRef.current.src = videoSource?.src;
        }
        if(props.setRef) props.setRef(videoRef.current);
    },[videoSource, props]);

    useEffect(()=>{
        if(videoRef.current && recordingState === RecordingState.STOPPED){
            videoRef.current.controls = true;
        } else if(videoRef.current){
            videoRef.current.controls = false;
        }
    },[recordingState]);

    return (
        <video 
            autoPlay={true}
            muted
            ref={videoRef}
            className={styles.display}
        ></video>
    );
}

export default React.memo(VideoDisplay);
import { BehaviorSubject } from "rxjs";
import { kitFetchExternal } from "@ekultur/fetch";

import { getFromCache } from "./cache";
import { FormUserInfo } from "../components/userInfo/SubmitUserInfo";
import { getResponseFromCache, MemoryResponse, MemoryResponses } from "./response";

export type UserInfo = FormUserInfo & {
    contact_email: string,
    name: string,
    place: string,
    sex: string
    work: string,
};

export type UserResponses = MemoryResponses & {
    items: undefined;
    responseList: BehaviorSubject<MemoryResponse>[] | undefined;
}

export const getUserInfo = () => {
    const userInfo = getFromCache("userInfo");
    if(!userInfo.value){
        requestUserInfo();
    }
    return userInfo as BehaviorSubject<UserInfo|undefined>;
}

const requestUserInfo = () => {
    kitFetchExternal(`${window._env_.REACT_APP_MEMORY_API}/user/`)
    .then((response: UserInfo) => {
        getFromCache("userInfo").next(response);
    })
    .catch(() => {
        console.warn("Failed to get userInfo")
    })
}

export const postUserInfo = (info: UserInfo | FormUserInfo) => {
    const userInfo = getFromCache("userInfo") as BehaviorSubject<UserInfo|undefined>;;

    return kitFetchExternal(`${window._env_.REACT_APP_MEMORY_API}/user/`, {
        body: JSON.stringify(info),
        method: "PATCH"
    })
    .then((value: UserInfo) => {
        userInfo.next(value);
        return Promise.resolve();
    })
    .catch(() => {
        return Promise.reject();
    })
}

const kitFetchUserResponses = (url: string, options?: RequestInit) => 
    <Promise<MemoryResponses>>kitFetchExternal(url, options);

const fetchUserResponses = (subject: BehaviorSubject<UserResponses|undefined>, offset: number) => {
    return kitFetchUserResponses(`${window._env_.REACT_APP_MEMORY_API}/user/responses?offset=${offset}`,)
    .then((responses: MemoryResponses) => {
        mergeResponses(subject, responses);
    })
    .catch(() => {

    })
}

export const getUserResponses = (offset:number = 0) => {
    const userResponses = getFromCache("userResponses") as BehaviorSubject<UserResponses | undefined>;
    if(!userResponses.value || userResponses.value.count <= offset ){
        fetchUserResponses(userResponses, offset);
    }
    return userResponses;
}

const mergeResponses = (responses$: BehaviorSubject<UserResponses|undefined>, responses: MemoryResponses) => {
    const prevValue = responses$.value;

    const response$List = responses.items?.map(val => {
        const cacheItem = getResponseFromCache(val.uuid!);
        cacheItem.next(val);
        return cacheItem as BehaviorSubject<MemoryResponse>;
    })

    if(!prevValue){
        responses$.next({
            ...responses,
            items: undefined,
            responseList: response$List
        });
    } else {
        responses$.next({
            ...responses,
            items: undefined,
            responseList: [...(prevValue.responseList || []), ...(response$List || [])],
            count: prevValue.count + responses.count
        }) 
    }
}
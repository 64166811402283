import React from "react"
import { Route, Switch, useRouteMatch } from "react-router"
import { Header } from "../../header/Header";
import MyPage from "./MyPage";
import PageMenu from "./PageMenu";
import UserInfoForm from "./UserInfoForm";

import styles from "./styles.module.scss";

const MyPageRouter = () => {

    const {path} = useRouteMatch();

    return (
        <div className={styles.userPage}>
            <div className={styles.top}>
                <PageMenu />
            </div>
            <section>
                <Switch>
                    <Route exact path={path}>
                        <MyPage />
                    </Route>
                    <Route path={`${path}/userInfo`}>
                        <UserInfoForm />
                    </Route>
                    <Route path={path}>
                        <span>Siden kan ikke nås</span>
                    </Route>
                </Switch>
            </section>
            <Header />
        </div>
  
    );
};

export default MyPageRouter; 
import React, { useContext, useState } from "react";
import { getContributors } from "../../../../../../api/contributors";
import Button from "../../../../../../components/button/Button";
import Dialogue from "../../../../../../components/dialogue/Dialogue";
import { useApi } from "../../../../../../state/hooks/useApi/useApi";
import { useObservable } from "../../../../../../state/hooks/useObservable/useObservable";
import { MemoryContext } from "../../../../memoryWizard";
import { SubmitActionType } from "../../state/reducer";

import styles from "./styles.module.scss";
import useAuthorString, { TranslationKey } from "./translationConfig";

const ContributorSelector = () => {
    const memoryContext = useContext(MemoryContext);
    const state = memoryContext.submitState!;
    const dispatch = memoryContext.submitDispatch!;
    
    const label = [
        useAuthorString(TranslationKey.CONTRIBUTOR_PROMT)
    ];

    const contributors = useApi(getContributors);

    const AnswerOrSelector = () => {
        const [edit, setEdit] = useState(false);

        const contributorUuid = useObservable(state.contributor);
        const authoriIsSet = useObservable(state.authorIsSet$);

        const Answer = () => (
            <Button 
                label={contributors?.find(contributor => contributor.uuid === contributorUuid)?.name}
                onClick={() => setEdit(true)}
                className={styles.accepted}
            />
        );

        const Selector = () => {
            const contributorSelected = (event: React.FormEvent<HTMLSelectElement>) => {
                dispatch({type: SubmitActionType.SET_CONTRIBUTOR, payload: event.currentTarget.value});
                setEdit(false);
            }
        
            return (
                <div className={styles.inputBox} key="input">
                    <div className={styles.inputRow}>
                        <select onInput={contributorSelected} value={contributorUuid}>
                            <option disabled value=""></option>
                            {contributors?.map(contributor => (
                                <option value={contributor.uuid} key={contributor.uuid}>{contributor.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            );
        }

        const view = (
            contributorUuid && !edit ? <Answer /> : <Selector />
        )

        return (
            <Dialogue questions={label} answer={view} animate={authoriIsSet === undefined} />
        )
    }

    return (
         <AnswerOrSelector />
    );
};

export default ContributorSelector;
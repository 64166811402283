import React from "react"
import { Route, Switch, useRouteMatch } from "react-router"
import EditResponse from "./verify/EditResponse";
import VerifyResponse from "./verify/VerifyResponse";


const ResponseRouter = () => {

    const {path} = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <span>Siden kan ikke nås</span>
            </Route>
            <Route path={`${path}/:uuid/edit`}>
                <EditResponse />
            </Route>
            <Route path={`${path}/:uuid/verify`}>
                <VerifyResponse />
            </Route>
            <Route path={path}>
                <span>Siden kan ikke nås</span>
            </Route>
        </Switch>
    );
};

export default ResponseRouter; 
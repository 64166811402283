import React, { useEffect, useRef, useState } from "react";
import ThreeDots from "../threeDots/ThreeDots";
import cn from "classnames";

import styles from "./styles.module.scss";

const Dialogue = (props: {questions: string[], answer?: JSX.Element, animate?: boolean}) => {
    const {questions, answer: answer, animate} = props;
    const prevQuestions = useRef<string[]>();
    const dialogueRef = useRef<HTMLDivElement>(null);

    const [displayArray, setArray] = useState<string[]>([]);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);
    
    useEffect(()=> {
        let timeout: NodeJS.Timeout | undefined;

        const addQuestion = (index: number) => {
   
            const newPortion = questions.slice(0,index);
            setArray(newPortion);
            prevQuestions.current = newPortion;
              
            if(index < questions.length){
                const nextIndex = index + 1;
                timeout = setTimeout(() => addQuestion(nextIndex), 1200);
                dialogueRef.current?.scrollIntoView({behavior:"smooth"});
            } else {
                setTimeout(() => {
                    setShowAnswer(true);
                    dialogueRef.current?.scrollIntoView({behavior:"smooth"});
                }, 100); 
            }
        }
        
        if(animate){
            setShowAnswer(false);
            addQuestion(0);
        } else {
            //Deep equalitity check when question array changes to avoid blinking
            if(questions.some((val, index) => val !== prevQuestions.current?.[index])){
                prevQuestions.current = questions;
                setArray(questions);
                setShowAnswer(true);
                dialogueRef.current?.scrollIntoView({behavior:"smooth"});
            //Fallback if there are no questions
            } else if(questions.length === 0){
                setShowAnswer(true);
            }
        }

        return () => {
            if(timeout){
                clearTimeout(timeout);
            }
        }
    },[questions, animate]);

    return (
        <div ref={dialogueRef} className={styles.dialogue}>
            {displayArray.map((part,index) => (
                <div className={styles.question} key={part+index}>{part}</div>
            ))}
            {displayArray.length < questions.length && (
                <div className={cn(styles.question, styles.forDots)}>
                    <ThreeDots/>
                </div>
            )}
            {(showAnswer && answer) &&  answer}
        </div>
    );
}

export default Dialogue;
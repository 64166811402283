import React from "react"
import { Route, Switch, useRouteMatch } from "react-router"
import Callback from "./callback";


const Oauth2Router = () => {

    const {path} = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <span>Siden kan ikke nås</span>
            </Route>
            <Route path={`${path}/callback`}>
                <Callback />
            </Route>
        </Switch>
    );
};

export default Oauth2Router; 
import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";

import { ItemMedia } from "../../memoryService";

import styles from './styles.module.scss';
import MediaDisplay from "../../../../components/mediaDisplay/MediaDisplay";

type AnswerPartProps = React.HTMLProps<HTMLDivElement> & {
    text: string | undefined,
    mediaEntry?: ItemMedia, 
}

const AnswerPart = (props: AnswerPartProps) => {
    const {mediaEntry, text, className, ...rest} = props;

    const answerRef = useRef<HTMLDivElement>(null);
    
    useEffect(()=>{
        answerRef.current?.scrollIntoView({behavior:"smooth"});
    },[])

    return (
        <div ref={answerRef} className={cn(styles.answer, className)} {...rest}>
            {mediaEntry && 
                <div className={styles.mediaContainer}>
                    <MediaDisplay item={mediaEntry}/>
                </div>
            }
            <p>{text}</p>
        </div>
    );
}

export default AnswerPart;
import React, {useContext, useEffect, useReducer} from "react";
import { AppContext } from "../../App";
import Dialogue from "../dialogue/Dialogue";
import { useObservable } from "../../state/hooks/useObservable/useObservable";
import BirthPlaceDialogue from "./BirthPlaceDialogue";
import BirthYearDialogue from "./BirthYearDialogue";
import NewsletterDialogue from "./NewsletterDialogue";
import useTranslatedString, { TranslationKey } from "./translationConfig";
import userInfoReducer, { UserInfoActionType } from "./state/reducer";

/**
    Added complexity as the current userInfo dictates if each part of the dialogue should be displayed or not,
    and the current form progress dictates what questions are displayed. Therefore we use both the "global" userInfo object
    as well as the userInfo object for the submit dialogue. The "global" userInfo object should not be updated before the
    form is submitted.
    
    NewsletterDialogue is only hidden when previously accepted. 

    Like in the memoryWizard a null value indicates a question has been skipped
 */

export interface FormUserInfo {
    allow_newsletter?: boolean|null; 
    birthplace?: string|null; 
    year_of_birth?: string|null;
}

type SubmitUserInfoProps = {
    infoCallback: (info: FormUserInfo) => void
    initialState?: FormUserInfo | undefined
}

const SubmitUserInfo = ({infoCallback, initialState = {}} : SubmitUserInfoProps) => {
    const appContext = useContext(AppContext);
    const userInfo = useObservable(appContext.userInfo$);

    const [state, dispatch] = useReducer(userInfoReducer, initialState);
 
    const aboutUserInfo = [
        useTranslatedString(TranslationKey.ABOUT_USER_INFO_1)
    ];

    //Pass current userinfo as input steps will be skipped if data exists
    useEffect(() => {
        if(userInfo){
            const formUserInfo: FormUserInfo = {
                allow_newsletter: userInfo.allow_newsletter ? true : undefined,
                year_of_birth: userInfo.year_of_birth,
                birthplace: userInfo.birthplace
            };
            dispatch({type: UserInfoActionType.SET_USER_INFO, payload: formUserInfo})
        }
    },[userInfo])

    useEffect(() => {
        if(state){
            infoCallback(state);
        }
    },[state])

    const newLetterAndLocation = (
        <>
            { (!userInfo?.birthplace)
                && <BirthPlaceDialogue location={state?.birthplace} dispatch={dispatch} />
            }
            { (!userInfo?.year_of_birth && state?.birthplace !== undefined )
                && <BirthYearDialogue birthyear={state?.year_of_birth} dispatch={dispatch} />
            }
        </>
    );
    
    //Has not previously submitted any userinfo
    const missingUserInfo = !userInfo?.birthplace || !userInfo?.year_of_birth;
    const newsLetterIsSet = state?.allow_newsletter !== undefined && state?.allow_newsletter !== null;

    return (
        <>
            { !userInfo?.allow_newsletter
                && <NewsletterDialogue newsletter={state?.allow_newsletter} dispatch={dispatch}/>
            }
            { (missingUserInfo && newsLetterIsSet) && <Dialogue 
                    questions={aboutUserInfo} 
                    answer={newLetterAndLocation} 
                    animate={state?.year_of_birth === undefined && state?.birthplace===undefined}
                />
            }
        </>
    );
}

export default SubmitUserInfo;
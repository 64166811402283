import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
import Button, { ButtonProps } from "./Button"
import classNames from "classnames";
import { useLocizeString } from "../../state/hooks/utils";


type SubmitButtonProps = ButtonProps & {
    /**
     * controls if submit or skip text should be displayed
     */
    isValid: boolean;
    /**
     * id of form if it is positioned **outside** of a form it should submit
     */
    form?: string;
}

/**
 * A pre-styled button with type submit for submitting an answer
 */
const SubmitButton = (props: SubmitButtonProps) => {
    const {isValid: hasInput, className, type, label, ...rest} = props;

    const continueLabel = useLocizeString("memoryForm", "continue", "Gå videre");
    const skipLabel = useLocizeString("memoryForm", "skip", "Hopp over");

    return (
        <Button
            type={type ? type : "submit"}
            className={cn(styles.submitButton, classNames, hasInput && styles.hasInput)}
            materialIcon={hasInput ? "east" : "skip_next"}
            label={hasInput ? label ? label : continueLabel: skipLabel}
            {...rest}
        /> 
    )
}

export default SubmitButton;
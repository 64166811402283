import { Loader } from "@googlemaps/js-api-loader";
import React from "react";
import { MapService } from "./mapService";

export const mapsLoader = new Loader({
    apiKey: "AIzaSyDvmK6SWv9gXlhy4yUB1Axkh_0Xu3v_lZ0",
    libraries: ["places"]
});

export const initializeMap = (
    initialCoords: string[] | undefined,
    mapRef: React.RefObject<HTMLDivElement>,
    searchRef: React.RefObject<HTMLInputElement>,
    setIsValid: (isValid: boolean) => void,
    setCoordinates: (coords: string[]) => void,
) => {
    const callbacks: (() => void)[] = [];

    mapsLoader.load().then(() => {
        if(mapRef.current && searchRef.current){
            const map = new google.maps.Map(mapRef.current, {
                center: { lat: 64, lng: 15},
                zoom: 4,
                disableDefaultUI: true
            });

            const searchBox = new google.maps.places.SearchBox(searchRef.current, {});

            const mapService = new MapService(map, searchBox, initialCoords);
            mapService.markers$.subscribe((markers) => {
                setIsValid(markers.length > 0);
                setCoordinates(markers.reduce<string[]>((acc, res) => {
                    acc.push(JSON.stringify(res.getPosition()!.toJSON()));
                    return acc;
                }, []));
                if(searchRef.current && searchRef.current.value !== ""){
                    searchRef.current.value = "";
                }
            });


            // Yes, ugly hack. But the search box offers no event for when the autocomplete box is displayed
            // And the results box is created asyncronusly. Consider implementing search api in stead
            setTimeout(() => {
                const autoCompleteBox = document.querySelector(".pac-container") as HTMLDivElement;
    
                if(autoCompleteBox){
                    const observer = new MutationObserver(() => {
                        if(autoCompleteBox?.style.display !== "none"){
                            //need to position the search result box, placed on body by maps, 
                            //relative to the search box, to scroll the dialogue rather than body
                            searchRef.current?.parentElement?.appendChild(autoCompleteBox);
                            autoCompleteBox.scrollIntoView();
                        }
                    });
                    observer.observe(autoCompleteBox, {attributes: true, childList: false});

                    callbacks.push(()=>{
                        observer.disconnect();
                        //not cleaned up by maps, so remove the element to avoid duplicates
                        autoCompleteBox.remove();
                    });
                }
            }, 2000);
        }
    });
    return callbacks;
}
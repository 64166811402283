import { kitFetchExternal } from "@ekultur/fetch";
import { BehaviorSubject } from "rxjs";

interface Contributor {
    address: string;
    created: string;
    email: string;
    modified: string;
    name: string;
    owner_id: number;
    phone: string;
    place: string;
    publishing_name: string;
    sex: string;
    uuid: string;
}

export const getContributors = () => {
    const $contributors = new BehaviorSubject<Contributor[]>([]);

    kitFetchExternal(`${window._env_.REACT_APP_MEMORY_API}/contributors/`)
        .then((data: Contributor[]) => {
            $contributors.next(data);
        })
        .catch((error: any) => {});

    return $contributors;
}
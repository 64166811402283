import { BehaviorSubject } from "rxjs";

const cache: {[key:string] : BehaviorSubject<any>} = {};

export const getFromCache = (key: string) => {
    if(!cache[key]){
        cache[key] = new BehaviorSubject(undefined);
    }
    return cache[key];
}

export const deleteFromCache = (key: string) => {
    delete cache[key];
}
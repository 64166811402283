import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { stripHtml } from "string-strip-html";

import { getTopic } from "../../api/topics";
import { AppContext } from "../../App";
import Button from "../../components/button/Button";
import DmsImage from "../../components/image/DmsImage";
import { Header } from "../../header/Header";
import { useApiWithArgs } from "../../state/hooks/useApi/useApi";
import { useObservable } from "../../state/hooks/useObservable/useObservable";
import { useLocizeString, usePrevious, useQuery } from "../../state/hooks/utils";
import AboutTopic from "./AboutTopic";
import Conversation from "./Conversation";
import TopicResponses from "./TopicResponses";

import styles from "./styles.module.scss";

const Topic = () => {  

    const { name } = useParams<{name: string}>();
    const [showConversation, setShow] = useState(false);
    const prevShowValue = usePrevious(showConversation);
    const appContext = useContext(AppContext);
    const i18n = useObservable(appContext.i18n$);

    const query = useQuery();
    const queryMemo = query.get("showConversation");
    const history = useHistory();
    const location = useLocation();

    const topic = useApiWithArgs(getTopic, [name]);
    const authorData = topic?.authors?.[0];

    const sendResponseLabel = useLocizeString("topic", "sendResponseLabel", "Del ditt minne her");
    
    useEffect(()=>{
        let querySaysShow = !!queryMemo;

        if(prevShowValue !== showConversation){
            if(showConversation && !querySaysShow){
                query.set("showConversation","true");
                history.replace({pathname: location.pathname, search: query.toString()});
                querySaysShow = true;
            } else if(!showConversation && querySaysShow){
                query.delete("showConversation");
                history.replace({pathname: location.pathname, search: query.toString()});
                querySaysShow = false;
            }
        }
        if(showConversation !== querySaysShow){
            setShow(querySaysShow);
        }
    },[showConversation, queryMemo, prevShowValue, history, location.pathname, query]);
    
    const toggleReply = () => {
        setShow(!showConversation);
    }

    const cleanString = (htmlString: string | undefined) => {
        if(htmlString){
            return stripHtml(htmlString, {ignoreTags: ["br","div","bold","a"]}).result
        }
        return "";
    };

    return (
        <>
            <div className={styles.top}>
                <section>
                    <div className={styles.topicHeader}>
                        <div>
                            <h1>{topic && (topic.name_i18n?.[i18n] || topic.name)}</h1>
                            <p dangerouslySetInnerHTML={{__html: cleanString(topic && (topic.details_i18n?.[i18n] || topic.details))}}></p>
                            {topic?.is_open && <Button 
                                label={sendResponseLabel}
                                className={styles.replyButton}
                                onClick={toggleReply}
                            />}
                        </div>
                        <div>
                            {topic && <DmsImage dmsId={topic.dms_id} />}
                        </div>
                    </div>
                </section>
                { authorData && <AboutTopic authorData={authorData} />}
            </div>
            {topic && <TopicResponses uuid={topic.uuid} />}
            <Header owner={topic?.owner} />
            {topic?.is_open && <Conversation topicId={topic.uuid} showConversation={showConversation} setShow={setShow}/>}
        </>
    )
}

export default Topic;


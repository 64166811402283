import {codeToToken} from "@ekultur/authentication";
import { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { AppContext } from "../../App";

const Callback = () => {
    const queryParams = new URLSearchParams(useLocation().search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    const history = useHistory();

    const isLoggedIn = useContext(AppContext).isLoggedIn$;

    const loginCompleted = useCallback((continueOnPath: string) => {
        history.replace(continueOnPath);
        isLoggedIn.next(true);
    }, [history, isLoggedIn]);

    useEffect(() => {
        if(code && state){
            codeToToken({
                code: code,
                state: state,
                redirectUri: null,
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: (originalPath: {previousPath: string}) => loginCompleted(originalPath.previousPath),
                onError: () => console.log("error")
            })
        }
    }, [code, state, loginCompleted]);

    return (
        <span>Henter logininformasjon..</span>
    );
}

export default Callback;


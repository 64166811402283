import {getDmsUrl} from "@ekultur/dms-url-generator";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

import styles from "./styles.module.scss";

const validSizes = [400, 600, 800, 1200];

const useDmsUrl = (dmsId: string | undefined) => {
    const [dmsUrl, setUrl] = useState();
    
    useEffect(() => {
        if(dmsId){
            setUrl(getDmsUrl(dmsId));
        }
    }, [dmsId]);

    return dmsUrl;
}

type DmsImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    dmsId: string | undefined;
}

const DmsImage = (props: DmsImageProps) => {

    const {dmsId, ...rest} = props;
    
    const dmsUrl = useDmsUrl(dmsId);

    const containerRef = useRef<HTMLDivElement>(null);
    const [imageSrc, setSrc] = useState<string>();

    useEffect(() => {
        if(containerRef.current && dmsUrl){
            const containerWidth = containerRef.current.offsetWidth;
            const containerHeight = containerRef.current.offsetHeight;
            const maxDim = Math.max(containerWidth, containerHeight);

            const width = validSizes.reduce((prev,current) => 
                Math.abs(current - maxDim)<Math.abs(prev - maxDim) ? current : prev);

            const url = `${dmsUrl}?dimension=${width}x${width}`
            if(url !== imageSrc){
                setSrc(url);
            }
        }
    },[containerRef, dmsUrl, imageSrc])

    return (
        <div ref={containerRef} className={styles.imageContainer}>
            <img src={imageSrc} className={styles.image} {...rest} />
        </div>
    )
}

export default DmsImage;
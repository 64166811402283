import React from "react";
import { stripHtml } from "string-strip-html";
import { getResponses } from "../../api/topicFrontPage";
import DmsImage from "../../components/image/DmsImage";
import { useApiWithArgs } from "../../state/hooks/useApi/useApi";
import { useLocizeString } from "../../state/hooks/utils";

import styles from "./styles.module.scss";

const TopicResponses = ({uuid}:{uuid: boolean}) => {
    
    const responses = useApiWithArgs(getResponses, [uuid]);
    const responseCountText = useLocizeString("topic", "responsesCount", "minner innsamlet");
    const noAuthorName = useLocizeString("topic", "noAuthorName", "Anonym");

    const textStyle = (text: string) => (
        text.length < 150 ? styles.shortAnswer : styles.longAnswer
    )

    const display = (
        <section>
            <h2>{`${responses?.responsesCount} ${responseCountText}`}</h2>
            <div className={styles.overflowContainer}>    
                <div className={styles.topicResponses}>
                    {responses?.selectedItems?.map((response,index) => (
                        <a className={styles.response} key={response.uuid + index} href={response.presentation_url}>
                            {
                                response?.media && (
                                    <div className={styles.mediaContainer}>
                                        <DmsImage dmsId={response.media.value} />
                                        <label>{response.media.label}</label>
                                    </div>                
                                )
                            } 
                            {
                                response?.value && (
                                    <div 
                                        className={textStyle(response.value)}
                                    >
                                        {stripHtml(response.value, {ignoreTags: ["br"]}).result}
                                    </div>
                                )
                            }                 
                            <span className={styles.author}>{response.author || noAuthorName}</span>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    )
    
    return (
        responses 
            ? display  
            : <div className={styles.loader}></div>
    );
}

export default TopicResponses;
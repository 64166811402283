import { useState, useEffect, useCallback } from "react";
import { getPreviewUrl } from "../../api/upload";
import useAudioOnCanvas from "../../state/hooks/useCanvas/useCanvas";
import Button from "../button/Button";

import styles from "./styles.module.scss";

type AudioPlayerProps = {
    src: string|undefined, 
    dmsId?: string, 
    hideControls?:boolean
    onError: () => void,
    onLoad: () => void
}

const AudioPlayer = (props: AudioPlayerProps) => {

    const {src, dmsId, hideControls, onError, onLoad} = props;

    const {containerRef, canvasRef, setData} = useAudioOnCanvas();
    const [source, setSource] = useState<AudioBufferSourceNode>();
    const [isPlaying, setIsPlaying] = useState(false);

    const createsSource = useCallback(() => {
        const audioContext = new AudioContext();
        const source = audioContext.createBufferSource();
        setSource(source);
        
        if(src){
            fetch(src).then(response => {
                if(response?.ok){
                    onLoad();
                    response.blob().then(blob => {
                        blob.arrayBuffer().then(buffer => {
                            audioContext.decodeAudioData(buffer).then(data => {
                                source.buffer = data;
                                source.connect(audioContext.destination);
                                const channelData = data.getChannelData(0);
                                setData(channelData);
                            });
                        })
                    })
                }
            }).catch((reason) => {
                onError();
            })
        }

        source.onended = () => {
            setIsPlaying(false);
            createsSource();
        }
    },[props.src, props.dmsId, setData])

    useEffect(() => {
        createsSource();
    }, [props.src, createsSource]);

    const togglePlay = () => {
        if(isPlaying){
            source?.stop();
            createsSource();
        } else {
            source?.start();
        }
        setIsPlaying(!isPlaying);
    }

    return (
        <div ref={containerRef} className={styles.audioPlayer}>
            <canvas ref={canvasRef} />
            {!props.hideControls && <Button materialIcon={isPlaying ? "stop_circle" : "play_circle"} onClick={togglePlay}/>}
        </div>
    );
};

export default AudioPlayer;

import React, { useContext, useEffect } from "react";
import styles from './styles.module.scss';

import { MediaRecorderContext } from "../mediaRecorder";
import VideoDisplay from "../videoDisplay/VideoDisplay";
import { useObservable } from "../../../../../state/hooks/useObservable/useObservable";
import { MediaType, RecordingState } from "../mediaRecorderService";
import RecordControls from "../recordControls/RecordControls";

const VideoRecorder = () => {

    const context = useContext(MediaRecorderContext);
    const state = useObservable(context.recorder?.recorderState$);

    useEffect(() => {
        if(state === RecordingState.STOPPED){
            context.inputService?.addMediaToEditedPart(MediaType.video, context.recorder!.lastRecording);
        }
    },[state, context])

    useEffect(() => {
        context.recorder?.init(MediaType.video);
        
        return(() => {
            context.recorder?.clear();
        })
     },[context])
     
    return (
        <div className={styles.container}>
            <div className={styles.preview}>
                <div className={styles.mediaContainer}>
                    <VideoDisplay></VideoDisplay>
                </div>
            </div>
            <RecordControls />
        </div>
    );
}

export default VideoRecorder;
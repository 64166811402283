import { BehaviorSubject } from "rxjs";
import { kitFetchExternal } from "@ekultur/fetch";
import { ItemAnswer } from "../packages/memoryWizard/memoryService";
import { getUserResponses } from "./user";
import { deleteFromCache, getFromCache } from "./cache";
import { FormUserInfo } from "../components/userInfo/SubmitUserInfo";

const kitFetchMemory = (url: string, options?: RequestInit) => <Promise<MemoryResponse>>kitFetchExternal(url, options);

interface ResponseValue {
    topic_item: {
        uuid: string;
    };
    value: string;
    order_number: number;
    uuid?: string;
}

export interface ResponseMedia {
    topic_item: {
        uuid: string;
    };
    dms_id: string;
    description?: string;
    order_by_number: number;
    owner?: string;
    mime_type: string;
    uuid?: string;
}

export interface MemoryResponse {
    topic: {
        uuid: string;
        name?: string;
        name_i18n?: {[i18n: string]: string};
    };
    values: ResponseValue[];
    media?: ResponseMedia[];
    published?: boolean;
    submitted?: boolean;
    author_alias?: string;
    author_anonymus?: boolean;
    contributor?: {
        uuid: string;
        display_name?: string;
    }
    uuid?: string;
    created?: string;
    v2?: boolean;
    image_dms_id?: string;
}

export interface MemoryResponses {
    count: number; 
    items?: MemoryResponse[]; 
    limit: number; 
    offset: number;
    total_count: number;
}

export const addAnswersAndSubmit = (response: MemoryResponse, memory: { [uuid: string]: BehaviorSubject<ItemAnswer | undefined> }, lng: string, email?: string) => {

    if (email) {
        return fetch(`${window._env_.REACT_APP_MEMORY_API}/response/`, {
            body: addMemoryToResponseJson(response, memory),
            method: "POST",
            headers: {
                'X-user-email': email,
                'Content-type': 'application/json',
                'Accept-Language': lng
            }
        })
        .then((response) => {
            if (response.ok) {
                response.json().then((value: MemoryResponse) => {
                    const response$ = getResponseFromCache(value.uuid!);
                    response$.next(value);
                })
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        })
        .catch((error: any) => {
            return Promise.reject();
        });
    } else {
        return kitFetchMemory(`${window._env_.REACT_APP_MEMORY_API}/response/`, {
            body: addMemoryToResponseJson(response, memory),
            method: "POST"
        })
            .then((data) => {
                const response$ = getResponseFromCache(data.uuid!);
                response$.next(data);
                return Promise.resolve();
            })
            .catch((error: any) => {
                return Promise.reject();
            });
    }
};

export const getResponseFromCache = (uuid: string) => {
    return getFromCache(`response|${uuid}`) as BehaviorSubject<MemoryResponse | undefined>;
}

export const getResponse = (uuid: string) => {
    const response = getResponseFromCache(uuid);

    if (!response.value) {
        kitFetchMemory(`${window._env_.REACT_APP_MEMORY_API}/response/${uuid}`)
            .then(data => {
                response.next(data);
            })
    }

    return response;
}


const addMemoryToResponseJson = (response: MemoryResponse, memory: { [uuid: string]: BehaviorSubject<ItemAnswer | undefined> }) => {

    for (const item_uuid in memory) {
        const topicItem = memory[item_uuid].getValue();

        topicItem?.values?.forEach(value => {
            response.values.push({
                topic_item: {
                    uuid: item_uuid
                },
                value: value.value,
                order_number: value.orderNumber,
                uuid: value.uuid
            });
        })

        topicItem?.media?.forEach(mediaItem => {
            if ("dmsId" in mediaItem) {
                response.media?.push({
                    topic_item: {
                        uuid: item_uuid
                    },
                    dms_id: mediaItem.dmsId,
                    description: mediaItem.description,
                    order_by_number: mediaItem.orderNumber,
                    uuid: mediaItem.uuid,
                    mime_type: mediaItem.mime_type
                });
            }
        })
    }
    return JSON.stringify(response);
}

export const verifyResponse = (uuid: string, token: string, userInfo: FormUserInfo) => {
    return fetch(`${window._env_.REACT_APP_MEMORY_API}/response/verify/${uuid}`, {
        body: JSON.stringify(userInfo),
        method: "POST",
        headers: {
            'X-User-Token': token,
            'Content-type': 'application/json'
        }
    })
    .then((response) => {
        if (response.ok) {
            response.json().then((value: MemoryResponse) => {
                const response$ = getResponseFromCache(value.uuid!);
                response$.next(value);
            })
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    })
    .catch((error: any) => {
        return Promise.reject();
    });
}

export const deleteResponse = (uuid: string) => {
    const response = getResponseFromCache(uuid);

    if (!response.value) {
        kitFetchMemory(`${window._env_.REACT_APP_MEMORY_API}/response/${uuid}`, {
            method: "DELETE"
        })
        .then(data => {
            response.next(undefined);
            deleteFromCache(`response|${uuid}`);
            const userResponses = getUserResponses();
            const prevResponses = userResponses.value;
            userResponses.next({
                responseList: prevResponses ? prevResponses.responseList?.filter(val => val.value.uuid !== uuid) : [],
                count: prevResponses? prevResponses.count - 1 : 0,
                total_count: prevResponses ? prevResponses.total_count -1 : 0,
                limit: prevResponses ? prevResponses.limit - 1 : 0,
                offset: prevResponses ? prevResponses.offset -1 : 0,
                items: undefined
            });
        })
        .catch(reason => {
            alert(reason);
        })
    }

    return response;
}
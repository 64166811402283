import { useParams } from "react-router";
import { useLocizeString, useQuery } from "../../../state/hooks/utils";
import SubmitUserInfo, { FormUserInfo } from "../../../components/userInfo/SubmitUserInfo";
import { Header } from "../../../header/Header";
import Dialogue from "../../../components/dialogue/Dialogue";


import { useState } from "react";
import SubmitButton from "../../../components/button/SubmitButton";
import { verifyResponse } from "../../../api/response";
import useHasUserInfo from "../../memoryWizard/components/submitMemory/state/hasUserInfo";

import styles from "./styles.module.scss"

const locizeNamespace = "verifyResponse";

const VerifyResponse = () => {
    const { uuid } = useParams<{uuid: string}>();
    const query = useQuery();
    const token = query.get("user_token");

    const [userInfo, setUserInfo] = useState<FormUserInfo>();
    const [submitSucceeded, setSubmitSucceeded] = useState<boolean>();
    const hasUserInfo = useHasUserInfo(userInfo);

    const verifyInfo = [
        useLocizeString(locizeNamespace, "greeting", "Tusen takk for at du bekrefter eposten din!")
    ]
    
    const userInfoForm = (
        <SubmitUserInfo infoCallback={setUserInfo} />
    )

    const finalInfo = [
        useLocizeString(locizeNamespace, "submitInfo1", "Takk for at du delte ditt minne"),
        useLocizeString(
            locizeNamespace, 
            "submitInfo2", 
            "Dersom du senere ønsker å se eller redigere minnet trenger du bare å opprette en bruker i minne med samme epost"
        )
    ]

    const submit = () => {
        if(token && userInfo)
            verifyResponse(uuid,token,userInfo).then(() => {
                setSubmitSucceeded(true);
            }).catch(() => {
                setSubmitSucceeded(false);
            });
    }

    const submitVerify = (
        <SubmitButton
            isValid={!!token && !!userInfo}
            materialIcon="send"
            label={useLocizeString(locizeNamespace, "submitLabel", "Send")}
            onClick={submit}
            key="submit"
        />
    )

    const dialogue = (
        <>
            <Dialogue questions={verifyInfo} answer={userInfoForm} />
            {hasUserInfo && <Dialogue questions={finalInfo} answer={submitVerify} animate />}
        </>
    )

    const errorDialogue = (
        <Dialogue questions={[
            useLocizeString(locizeNamespace, "errorLine1", "Beklager, det har skjedd en feil, eller så er eposten allrede bekreftet"),
            useLocizeString(locizeNamespace, "errorLine2", "Trykk på lenken i eposten igjen, eller send inn minnet på nytt"),
        ]} />
    )

    const completedMessage = (
        <Dialogue questions={[
            useLocizeString(locizeNamespace, "completedLine1", "Din epost er nå verifisert"),
            useLocizeString(locizeNamespace, "completedLine2", "Du kan nå lukke dette vinduet")
        ]} />
    )
    
    return(
        <>
            <div className={styles.top}></div>
            <section>
                <div className={styles.conversation}>
                    {(!token || submitSucceeded === false ) ? errorDialogue : submitSucceeded ? completedMessage : dialogue}
                </div>
            </section>
            <Header />
        </>
    );
    
}

export default VerifyResponse;
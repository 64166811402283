import { BehaviorSubject } from "rxjs";
import { getFromCache } from "./cache";

interface FrontPageResponses {
    responsesCount: number,
    selectedItems?: {
        author: string;
        uuid: string;
        presentation_url: string;
        value?: string;
        media?: {
            value: string
            type: MimeType,
            label: string
        }
    }[]
}

export const getResponses = (uuid: string): BehaviorSubject<FrontPageResponses> => {
    if(uuid === "demo"){
        return new BehaviorSubject({responsesCount: 0});
    }
    const topic = getResponsesFromCache(uuid);
    if(!topic.value){
        fetchResponses(uuid);
    }
    return topic;
}

const getResponsesFromCache = (uuid: string) => 
    getFromCache(`frontPageResponses|${uuid}`) as BehaviorSubject<FrontPageResponses>


const fetchResponses = (uuid: string) => {
    fetch(`${window._env_.REACT_APP_MEMORY_API}/topic_front_page_responses/${uuid}`)
    .then(response => response.json())
    .then(data => {
        getResponsesFromCache(uuid).next(data);
    });
}
import { useContext, useState } from "react";
import { MemoryResponse } from "../../../../../../api/response";
import { postUserInfo } from "../../../../../../api/user";
import { AppContext } from "../../../../../../App";
import Button from "../../../../../../components/button/Button";
import Dialogue from "../../../../../../components/dialogue/Dialogue";
import { MemoryContext } from "../../../../memoryWizard";
import { SubmitActionType } from "../../state/reducer";

import styles from "./styles.module.scss";
import useTranslatedString, { TranslationKey } from "./translationConfig";

const CompletedDialogue = () => {
    const memoryContext = useContext(MemoryContext);
    const appContext = useContext(AppContext);
    
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    
    const textArray = [
        useTranslatedString(TranslationKey.COMPLETED_LINE_1),
        useTranslatedString(TranslationKey.COMPLETED_LINE_2)
    ];

    const submittingLabel = useTranslatedString(TranslationKey.SUBMITTING);
    const sendLabel = useTranslatedString(TranslationKey.SEND_LABEL);

    const stateToResponse = () => {

        const response: MemoryResponse = {
            topic: {
                uuid: memoryContext.service?.topicId!
            },
            uuid: memoryContext.service?.responseUuid, 
            media: [],
            values: []
        };

        if(memoryContext.submitState){
            const state = memoryContext.submitState;

            if (state.psaudoSelected$.value) {
                response.author_alias = state.psaudonym$.value;
            } else if (state.collectorSelected$.value) {
                response.contributor = {
                    uuid: state.contributor$.value
                }
            }
    
            if (state.publish$.value) {
                response.published = true;
                response.submitted = undefined;
            } else {
                response.submitted = true;
                response.published = undefined;
            }
        }

        return response;
    }

    // This is more or less a mess, joining and clearing multiple contexts. Should be cleaned up
    // I would probably have provided the memoryService to the submitReducer and made it responsible for sending stuff

    const submit = () => {
        setIsSubmitting(true);

        const response = stateToResponse();

        memoryContext.service?.submit(response, appContext.i18n$.value, memoryContext.submitState?.email$.value).then(() => {
            setIsSubmitting(false);

            if(memoryContext.submitState?.userInfo$.value){
                postUserInfo(memoryContext.submitState.userInfo$.value);
            }

            memoryContext.submitDispatch?.({type: SubmitActionType.CLEAR});
        });
    }

    const submitButton = (
        <Button
            className={styles.submit}
            materialIcon="send"
            label={sendLabel}
            onClick={submit}
            key="submit"
        />
    );

    const submittingMessage = (
        <div className={styles.submitting}>
            <div className={styles.loader}></div>
            <span>{submittingLabel}</span>
        </div>
    );

    return (
        <>
            <Dialogue questions={textArray} answer={submitButton} animate={true}/>
            { isSubmitting && submittingMessage }
        </>
    );
}

export default CompletedDialogue;
import { BehaviorSubject, Subject } from "rxjs";

export interface MediaSource {
    isObject: boolean,
    src: any
}

export enum RecordingState {
    LOADING,
    READY,
    RECORDING,
    PAUSED,
    STOPPED
}

export enum MediaType {
    video = "video/webm",
    audio = "audio/webm"
}

export class MediaRecorderService {
    private _mediaRecorder: MediaRecorder | undefined;
    private _stream: MediaStream | undefined;
    public inputSourceArray = [] as string[];
    public lastRecording = "";

    public async init(mediaType: MediaType){
        if(mediaType === MediaType.video){
            navigator.mediaDevices.getUserMedia({video: true, audio: true}).then((stream) => {
                
                this._stream = stream;
                this.clear();
                
                navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
                    for(let device of deviceInfos){
                        if(device.kind === 'videoinput'){
                            this.inputSourceArray.push(device.deviceId);
                        }
                    }
                }).then(() => this.initMediaRecorder());
            })
        } else {
            this.initMediaRecorder(true);
        }
    }

    private _inputSourceIndex = new BehaviorSubject<number>(0);
    public get inputSourceIndex$(){
        return this._inputSourceIndex;
    }

    public setInputSourceIndex(index:number){
        this._inputSourceIndex.next(index);
        this.clear();
        this.initMediaRecorder();
    }

    private _mediaSource = new Subject<MediaSource>();    
    public get mediaSource$() {
        return this._mediaSource;
    }

    public recorderState$ = new BehaviorSubject<RecordingState>(RecordingState.LOADING);

    public async initMediaRecorder(isAudio?: boolean){
 
       const constraints: MediaStreamConstraints = {
            video: isAudio ? false : { deviceId: { exact: this.inputSourceArray[this._inputSourceIndex.value] } },
            audio: true
        };

        if(this._stream){
            this.clear();
        }

        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            this._stream = (stream);
            this._mediaSource.next({isObject: true, src: stream});
            
            const chunks = [] as Blob[];

            this._mediaRecorder = new MediaRecorder(stream);
            this.recorderState$.next(RecordingState.READY);
            
            this._mediaRecorder.onstart = () => {
                this.recorderState$.next(RecordingState.RECORDING)
            }
            this._mediaRecorder.onpause = () => {
                this.recorderState$.next(RecordingState.PAUSED)
            }
            this._mediaRecorder.onresume = () => {
                this.recorderState$.next(RecordingState.RECORDING)
            }
                    
            this._mediaRecorder.onstop = (e) => {
                var blob = new Blob(chunks, {type: isAudio ? MediaType.audio : MediaType.video});
                this.lastRecording = URL.createObjectURL(blob);
                this._mediaSource.next({isObject: false, src: this.lastRecording});
                this.recorderState$.next(RecordingState.STOPPED);
            }
            
            this._mediaRecorder.ondataavailable = (e) => {
                chunks.push(e.data);
            }
        })
        .catch(function(err) {
            console.log('The following error occurred: ' + err);
        })
    }

    public toggleRecording() {
        if(this.recorderState$.value === RecordingState.RECORDING){
            this._mediaRecorder?.pause();
        } else if(this.recorderState$.value === RecordingState.PAUSED){
            this._mediaRecorder?.resume();
        } else if(this.recorderState$.value === RecordingState.READY) {
            this._mediaRecorder?.start();
        }
        
    }

    public stopRecording() {
        this._mediaRecorder?.stop();
    }

    public async clear() {
        if(this._stream) {
            this._stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        this.lastRecording = "";
    }
}
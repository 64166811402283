import { useState } from "react";
import Button from "../button/Button";
import SubmitButton from "../button/SubmitButton";
import Dialogue from "../dialogue/Dialogue";
import Sequencer from "../sequencer/Sequencer";
import { useLocizeString } from "../../state/hooks/utils";


import styles from "./styles.module.scss";
import useTranslatedString, { TranslationKey } from "./translationConfig";
import { UserInfoAction, UserInfoActionType } from "./state/reducer";

type BirthYearProps = {
    birthyear: string | null | undefined;
    dispatch: React.Dispatch<UserInfoAction>;
} 

const BirthYearDialogue = ({birthyear, dispatch}: BirthYearProps) => {
    
    const questions = [
        useTranslatedString(TranslationKey.BIRTHYEAR_QUESTION)
    ];
    const placeholder = useTranslatedString(TranslationKey.BIRTHYEAR_PLACEHOLDER);
    const skipLabel = useLocizeString("memoryForm", "skip", "Hopp over");
    
    const [edit, setEdit] = useState(false);

    const setBirthyear = (year: string|null) => {
        dispatch({type: UserInfoActionType.SET_BIRTYEAR, payload: year});
    }

    const answer = (
        <Button 
            label={birthyear || skipLabel}
            onClick={() => setEdit(true)}
            className={styles.accepted}
        />
    );

    const Options = () => {
        const [input, setInput] = useState(birthyear || "");

        const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
            setInput(event.currentTarget.value);
        }

        const handleSubmit = (event: React.FormEvent) => {
            event.preventDefault();
            setBirthyear(input.length > 0 ? input : null);
            setEdit(false);
        }

        return (
            <form onSubmit={handleSubmit}>
                <Sequencer elements={[
                    <div className={styles.inputBox} key="input">
                        <div className={styles.inputRow}>
                            <input
                                type="text"
                                value={input}
                                placeholder={placeholder} 
                                onChange={handleInput}
                            />
                            <Button 
                                materialIcon="keyboard_return"
                                type="submit"
                            />
                        </div>
                    </div>,
                    <SubmitButton 
                        isValid={!!input && input.length > 3}
                        key="submit"
                    />
                ]} skipAnimation={birthyear !== undefined} />
            </form>
        )
    }

    const optionsOrAnswer = (
        birthyear !== undefined && !edit ? answer : <Options />
    );
   
    return (
        <Dialogue questions={questions} answer={optionsOrAnswer} animate={birthyear === undefined}/>
    )
}

export default BirthYearDialogue;